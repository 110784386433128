import { FC, memo, useEffect, useRef } from "react";

import { IMenuItem, ActionsMenu, TooltipWrapper, Loader, Chips } from "@/components";
import { useAppDispatch } from "@/hooks";
import {
  changeFavoriteStatus,
  fetchChangeFavoriteStatus,
  resetCopiedTemplate,
} from "@/store/strategies/my_strategies";
import { IStartegyCard, StrategyType } from "@/types";
import { getFormattedDate, searchStringInText } from "@/utils";

import styles from "./StrategyItem.module.scss";

import { ReactComponent as DownloadIcon } from "@images/download.svg";
import { ReactComponent as DragDropIcon } from "@images/drag-item.svg";
import { ReactComponent as StrategyIcon } from "@images/strategy.svg";

interface IProps {
  item: IStartegyCard;
  onSelect: (item: IStartegyCard) => void;
  onSelectMenu: (menuItem: IMenuItem, item: IStartegyCard) => void;
  onPositionDownload?: (item: IStartegyCard) => void;
  hasMenu?: boolean;
  style?: object;
  isDroppable?: boolean;
  isFavorites?: boolean;
  idx?: number;
  progress?: boolean;
  disabledDownload?: boolean;
  searchText?: string;
  isTemplate?: boolean;
  menuActions: IMenuItem[];
  copiedTemplateId?: number | null;
}

export const StrategyItem: FC<IProps> = memo(
  ({
    item,
    onSelect,
    hasMenu = false,
    style,
    onSelectMenu,
    isDroppable = false,
    isFavorites,
    idx,
    onPositionDownload,
    progress,
    disabledDownload,
    searchText,
    isTemplate = false,
    menuActions,
    copiedTemplateId = null,
  }) => {
    const { id, config, name, user, created_at, updated_at, permissions, show_on_home } = item;
    const dispatch = useAppDispatch();
    const itemRef = useRef<HTMLDivElement | null>(null);

    const selectItemHandler = (item: IStartegyCard) => {
      onSelect(item);
    };

    const animationEndHandler = (ev: React.AnimationEvent<HTMLDivElement>) => {
      if (isFavorites && show_on_home && ev.target === itemRef.current) {
        dispatch(changeFavoriteStatus(id));
        dispatch(fetchChangeFavoriteStatus({ value: !show_on_home, id }));
      }
    };

    const downloadLivePositionHandler = () => {
      if (onPositionDownload && !progress && !disabledDownload) onPositionDownload(item);
    };

    const selectMenuHandler = (menuItem: IMenuItem) => {
      onSelectMenu(menuItem, item);
    };

    const canDownloadPosition = Boolean(onPositionDownload && !isTemplate);

    useEffect(() => {
      setTimeout(() => {
        if (itemRef.current) itemRef.current.classList.add(styles.onStartAnimated);
      }, 50 + (idx || 0) * 35);
    }, [idx]);

    const getType = (type: StrategyType) => {
      return type === "core" ? "primary" : "secondary";
    };

    const groupName = (name: string) => {
      const result = searchStringInText(name, searchText);

      return result.length ? (
        result.map((item, idx) => (
          <span data-match={searchText?.toLowerCase() === item.toLowerCase()} key={idx}>
            {item}
          </span>
        ))
      ) : (
        <span>{name}</span>
      );
    };

    const transitionEndHandler = () => {
      if (copiedTemplateId) {
        dispatch(resetCopiedTemplate());
      }
    };

    useEffect(() => {
      if (copiedTemplateId && itemRef.current) {
        itemRef.current.scrollIntoView({ block: "center", behavior: "smooth" });
        itemRef.current.classList.add(styles.copied);
      }
    }, [copiedTemplateId]);

    return (
      <div
        className={styles.container}
        ref={itemRef}
        style={style}
        onAnimationEnd={animationEndHandler}
        data-template={isTemplate}
        data-copied={copiedTemplateId}
        onTransitionEnd={transitionEndHandler}
      >
        <div className={styles.inner} onClick={() => selectItemHandler(item)}>
          <div className={styles.iconContainer}>
            <StrategyIcon className={`${config.type === "overlay" ? styles.overlay : ""}`} />
            {isTemplate ? <span>Template</span> : null}
          </div>
          <div className={styles.textGroup}>
            <p className={styles.title}>{groupName(name)}</p>
            <p className={styles.footerText}>
              <span>
                Type: <Chips label={`${config.type}`} type={getType(config.type)} size="small" />
              </span>
              {id ? (
                <span>
                  ID: <span className={styles.number}>{`${id}`}</span>
                </span>
              ) : null}
            </p>
            <p className={styles.label}>Strategy</p>
          </div>
          <div className={styles.infoContainer}>
            <div className={styles.iconContainer}>
              {isDroppable && <DragDropIcon />}
              {!isDroppable && (
                <StrategyIcon className={`${config.type === "overlay" ? styles.overlay : ""}`} />
              )}
            </div>
            <div className={styles.textContainer}>
              <p className={styles.title}>Created By </p>
              {user?.email ? <p>{user.email}</p> : <p>BDF Company</p>}
              {created_at ? (
                <p>{getFormattedDate(created_at)}</p>
              ) : (
                !user?.email && updated_at && <p>{getFormattedDate(updated_at)}</p>
              )}
            </div>
          </div>
        </div>
        {hasMenu && <ActionsMenu menuItems={menuActions} onSelect={selectMenuHandler} />}
        {canDownloadPosition && (
          <div
            className={`${styles.downloadActionContainer} ${
              !progress && disabledDownload ? styles.disabled : ""
            }`}
            onClick={downloadLivePositionHandler}
          >
            {progress ? (
              <Loader small />
            ) : (
              <TooltipWrapper text="Download Live Positions">
                <DownloadIcon />
              </TooltipWrapper>
            )}
          </div>
        )}
      </div>
    );
  }
);
