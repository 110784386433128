import { useCallback, useEffect, useMemo } from "react";

import { paginator } from "@/constants";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { profileInfo } from "@/store/profile";
import {
  fetchMyStrategiesData,
  selectMyStrategies,
  setPage,
  setPerPage,
} from "@/store/strategies/my_strategies";
import { init } from "@/store/strategies/strategies";
import { prepareStrategiesList } from "@/utils";

export const useMyStrategies = () => {
  const dispatch = useAppDispatch();
  const { list, isLoading, page, perPage, copiedTemplateId } = useAppSelector(selectMyStrategies);
  const { profile } = useAppSelector(profileInfo);

  const strategiesList = useMemo(() => {
    const myStartegiesList = list && profile && list.filter((item) => item.user_id === profile.id);
    return myStartegiesList ? prepareStrategiesList(myStartegiesList) : [];
  }, [list, profile]);

  const selectPageHandler = useCallback((page: number) => {
    dispatch(setPage(page));
  }, []);

  const selectPerPageHandler = useCallback((perPage: number) => {
    dispatch(setPage(1));
    dispatch(setPerPage(perPage));
  }, []);

  const strategiesPaginator = useMemo(() => {
    return {
      page,
      perPage,
      setPage: selectPageHandler,
      setPerPage: selectPerPageHandler,
      perPageList: paginator.perPageList,
    };
  }, [selectPageHandler, selectPerPageHandler, page, perPage]);

  useEffect(() => {
    dispatch(init());
    if (!list) {
      dispatch(fetchMyStrategiesData());
    }
  }, []);

  return {
    strategiesPaginator,
    copiedTemplateId,
    strategiesList,
    isLoading,
  };
};
