import React, { FC } from "react";

import styles from "./ContentLayout.module.css";

interface IProps {
  children: React.ReactNode;
  topSpace?: number;
}

export const ContentLayout: FC<IProps> = ({ children, topSpace }) => {
  return (
    <div className={styles.container} style={{ marginTop: topSpace }}>
      {children}
    </div>
  );
};
