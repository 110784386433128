import { createSlice, createAsyncThunk, PayloadAction, createSelector } from "@reduxjs/toolkit";

import { pagesPermissionService } from "@/services";
import {
  IProfilePagePermissionType,
  IUserGlobalAccessConfig,
  IUserPagesAccessControl,
} from "@/types";
import { featurePermissions } from "@/utils";
import { PageItemType, pagePermission } from "@/utils/pagesPermissions";

import { RootState } from ".";

interface IInitialState {
  pages: Record<string, PageItemType> | null;
  config: Record<string, IProfilePagePermissionType> | null;
  globalAccess: IUserGlobalAccessConfig | null;
}

const initialState: IInitialState = {
  pages: null,
  config: null,
  globalAccess: null,
};

export const fetchPagesStatus = createAsyncThunk("pagesPermissionStatus/pagesStatus", async () => {
  try {
    const { data } = await pagesPermissionService.getUserPagesAccessStatus();
    return data;
  } catch (err) {
    return null;
  }
});

export const fetchFeaturesStatus = createAsyncThunk(
  "featuresPermissionStatus/featuresStatus",
  async () => {
    const { data } = await pagesPermissionService.getProfileGlobalAccessStatus();
    return data;
  }
);

export const pagesPermissionSlice = createSlice({
  name: "pagesPermission",
  initialState,
  reducers: {
    init: (state) => {
      state.pages = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchPagesStatus.fulfilled,
        (state, { payload }: PayloadAction<object | IUserPagesAccessControl | null>) => {
          if (payload && "config" in payload) {
            state.pages = pagePermission.preparePermissionsConfigList(payload);
            state.config = payload.config;
          }
        }
      )
      .addCase(fetchPagesStatus.rejected, (state, { payload }) => {
        //
      });

    // Get global access status data
    builder
      .addCase(fetchFeaturesStatus.fulfilled, (state, { payload }) => {
        if (payload && "config" in payload.result) {
          state.globalAccess = payload.result;
          featurePermissions.initPermissions(payload.result);
        }
      })
      .addCase(fetchFeaturesStatus.rejected, (state, { payload }) => {
        //
      });
  },
});

const root = (state: RootState) => state;

export const pagesStatusState = createSelector(root, (state) => state.pagesPermission);

export const { init } = pagesPermissionSlice.actions;

export default pagesPermissionSlice.reducer;
