import { useCallback, useEffect, useRef, useState } from "react";

import { debounce } from "@/utils";

import { useDetectEmptySpace } from "./hooks/useDetectEmptySpace";
import { useDropdownMultiSearch } from "./hooks/useDropdownMultiSearch";
import { Loader } from "../Loader/Loader";
import { VirtualList } from "../VirtualList/VirtualList";

import styles from "./DropdownMultySearch.module.scss";

import { ReactComponent as ClearIcon } from "@images/clear.svg";
import { ReactComponent as SecrhIcon } from "@images/search.svg";

type ItemType = { key: string; value: string; id?: number };

interface IDropdownProps<T> {
  list: ItemType[];
  onSelect: (name: T, item: ItemType[]) => void;
  onBlur?: () => void;
  selected: ItemType[];
  label?: string;
  name: T;
  disable?: boolean;
  placeholder?: string;
  loading?: boolean;
  isRequired?: boolean;
  labelIcon?: React.ReactNode;
  emptyListText?: string;
  transformTextToUpper?: boolean;
  CustomLabel?: () => JSX.Element;
  visibleRows?: number;
  isReadonly?: boolean;
}

const DEFAULT_ITEM_HEIGHT = 36;

export function DropdownMultiSearch<T extends string>({
  list,
  onSelect,
  onBlur,
  selected,
  label,
  name,
  disable,
  placeholder,
  loading,
  isRequired,
  labelIcon,
  emptyListText,
  transformTextToUpper,
  CustomLabel,
  visibleRows = 5,
  isReadonly = false,
}: IDropdownProps<T>) {
  const onSelectHandler = useCallback(
    (item: ItemType[]) => {
      onSelect(name, item);
    },
    [onSelect, name]
  );

  const {
    inputContainerRef,
    itemRef,
    inputRef,
    toggle,
    value,
    labelsCount,
    isOverflow,
    itemsCount,
    containerStyle,

    selectHandler,
    changeHandler,
    clearHandler,
    filteredList,
    removeItemHandler,
    toggleHandler,
    closeHandler,
  } = useDropdownMultiSearch({
    list,
    selected,
    onSelect: onSelectHandler,
    onBlur,
    transformTextToUpper,
  });

  const { listRef, isListOverflow } = useDetectEmptySpace({
    count: filteredList.length,
    visibleRows,
    itemHeight: DEFAULT_ITEM_HEIGHT,
  });

  const hasLabel = label || CustomLabel;
  const isListEmpty = filteredList.length === 0;
  const hasCustomLabel = !!CustomLabel;

  return (
    <div
      className={`${styles.container} ${disable ? styles.disabled : ""} ${
        toggle && !loading ? styles.open : ""
      } ${loading ? styles.loading : ""} ${hasCustomLabel ? styles.customLabel : ""}`}
      style={containerStyle as React.CSSProperties}
      data-readonly={isReadonly}
    >
      <div className={styles.innerWrapper} ref={itemRef}>
        <div className={`${styles.label} ${isRequired ? styles.required : ""}`}>
          {label ? <span className={styles.labelText}>{label}</span> : null}
          {labelIcon ? (
            <span className={styles.labelIcon} onClick={closeHandler}>
              {labelIcon}
            </span>
          ) : null}
          {CustomLabel ? <CustomLabel /> : null}
        </div>
        <div className={styles.labelWrapper} ref={listRef}>
          <div className={styles.inputContainer} onClick={toggleHandler}>
            <div className={styles.iconContainer}>
              <SecrhIcon />
            </div>
            {!!selected.length && (
              <div className={`${styles.iconContainer} ${styles.clear}`} onClick={clearHandler}>
                <ClearIcon />
              </div>
            )}
            {loading ? (
              <div className={styles.loaderContainer}>
                <Loader />
              </div>
            ) : (
              <div className={styles.inputListContainer} ref={inputContainerRef}>
                <span className={styles.elementsContainer}>
                  {selected.slice(0, labelsCount).map(({ value }, idx) => (
                    <span key={`${value}-${idx}`} className={styles.element}>
                      <span className={styles.labelText} title={value}>
                        {value}
                      </span>
                      <span
                        className={styles.removeItem}
                        onClick={(ev) => {
                          ev.stopPropagation();
                          removeItemHandler(value);
                        }}
                      >
                        {" "}
                        &times;
                      </span>
                    </span>
                  ))}
                  {isOverflow && (
                    <span className={[styles.element, styles.extended].join(" ")}>
                      <span className={styles.labelText}>
                        {itemsCount < 100 ? `+ ${itemsCount}` : `99 +`} items...
                      </span>
                    </span>
                  )}
                </span>
                <input
                  className={styles.input}
                  value={value}
                  name={name}
                  id={name}
                  onChange={changeHandler}
                  ref={inputRef}
                  placeholder={placeholder}
                  autoComplete="off"
                />
              </div>
            )}
          </div>
        </div>
        <div
          className={`${styles.listContainer} ${isListOverflow ? styles.reversePosition : ""} ${
            hasLabel ? "" : styles.withoutLabel
          }`}
        >
          <div className={styles.wrapper}>
            <ul className={styles.list}>
              {isListEmpty ? (
                <li className={`${styles.listItem} ${styles.empty}`}>
                  {emptyListText || "No Items Fround."}
                </li>
              ) : (
                <VirtualList visibleRows={visibleRows} itemHeight={DEFAULT_ITEM_HEIGHT}>
                  {filteredList.map((item) => (
                    <li
                      key={item.key}
                      className={`${styles.listItem} ${
                        selected.some((i) => i.value === item.value) ? styles.active : ""
                      } `}
                      onClick={() => selectHandler(item)}
                      style={{ height: DEFAULT_ITEM_HEIGHT }}
                    >
                      <span className={styles.info}>{item.value}</span>
                    </li>
                  ))}
                </VirtualList>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
