import { IPortfolioBase, IPortfolioOverlay, PortfolioStrategyTypeEnum } from "./portfolio";

export const enum VersionEnumType {
  VERSION_1 = "2024.12",
}

export interface IPortfolioBuilderPayload {
  base?: IPortfolioBase;
  overlay?: IPortfolioOverlay;
  benchmark?: string[];
  start_date?: string | null;
  end_date?: string | null;
  types?: Record<string, string>;
  user_defined_strategies?: Record<string, any>;
  is_predefined_template?: boolean;
  version?: VersionEnumType;
}

export interface ISeries {
  name: string;
  data: ((string | null)[] | (string | number | null)[])[];
  type?: string;
  color?: string;
}

export interface IPortfolioBuilderData {
  portfolio: { [key: string]: Record<string, number> };
  metrics: { [key: string]: Record<string, any> };
}

export interface IPortfolioBuilderDataResult {
  result: IPortfolioBuilderData;
}

export interface ISeriesData {
  data: ISeries[];
  dates: string[];
}

export interface IPortfolioBuilder {
  id: string;
  name: string | undefined;
  key: string | undefined;
  weights: string | null;
  type?: PortfolioStrategyTypeEnum;
  strategyId: number | null;
}

export interface IDateRange {
  start: string | null;
  end: string | null;
}
