import { FC } from "react";

import { Dropdown } from "@/components";
import { DropdownSearch } from "@/components/Dropdown/DropdownSearch";

import styles from "./StockETFsForm.module.scss";

interface IProps {
  list: any;
  selectTickerHandler: any;
  selectedTicker: any;
  disableOptions: any;
  isLoadingTickers: any;

  directionList: any;
  selectDirectionHandler: any;
  selectedDirection: any;
  isReadonly?: boolean;
}

export const StockETFsForm: FC<IProps> = ({
  list,
  selectTickerHandler,
  selectedTicker,
  disableOptions,
  isLoadingTickers,
  selectDirectionHandler,
  directionList,
  selectedDirection,
  isReadonly,
}) => {
  return (
    <div className={`${styles.row} ${styles.noSpace}`}>
      <div className={styles.itemWrapper}>
        <DropdownSearch<"ticker">
          list={list}
          onSelect={selectTickerHandler}
          selected={selectedTicker}
          placeholder="Search Ticker Name"
          name="ticker"
          label="Trading Ticker"
          disable={disableOptions || list.length === 0}
          isRequired={true}
          loading={isLoadingTickers}
          isReadonly={isReadonly}
          isUpperCase
        />
      </div>
      <div className={styles.itemWrapper}>
        <Dropdown
          list={directionList}
          onSelect={selectDirectionHandler}
          selected={selectedDirection}
          placeholder="Select Strategy Direction"
          name="direction"
          label="Strategy Direction"
          disable={disableOptions}
          isReadonly={isReadonly}
        />
      </div>
      <div className={`${styles.itemWrapper} ${styles.empty}`}></div>
    </div>
  );
};
