import { featuresGlobalList } from "@/constants";
import { IProfilePagePermissionType, IUserGlobalAccessConfig } from "@/types";

export interface PermissionItem extends Object {
  key: string;
}

export class FeaturePermissions {
  permissionsList: Record<string, IProfilePagePermissionType> | null = {};

  constructor() {
    //
  }

  initPermissions(data?: IUserGlobalAccessConfig) {
    if (data?.config?.features) this.permissionsList = data.config.features || null;
  }

  createFeaturesPermissionsList(data: IUserGlobalAccessConfig) {
    return data.config?.features || null;
  }

  createResourcesPermissionsList(data: IUserGlobalAccessConfig) {
    // if (data.config?.resources) {
    //   const keys = Object.keys(data.config?.resources) ;
    //   const result = keys.reduce((acc, key) => {
    //     const fieldKey = key as keyof IResourcesAccess;
    //     if (data.config?.resources) {
    //       acc = {...acc, ...data.config?.resources[fieldKey]}
    //     }
    //     return acc;
    //   }, {});
    // }
    return data.config?.resources || null;
  }

  createFeatureConfig(
    featureDataList: Record<string, IProfilePagePermissionType> | null,
    globalConfig: IUserGlobalAccessConfig | null
  ) {
    if (featureDataList === null) return { config: {} };
    return {
      config: { ...(globalConfig?.config || null), features: featureDataList },
    };
  }

  getList() {
    return this.permissionsList;
  }

  getAccessStatus(key: string) {
    if (this.permissionsList) {
      return this.permissionsList[key] || null;
    }
  }

  getFeatureKey(featureKey: string) {
    return featuresGlobalList.find((item) => item.featureKey === featureKey)?.key || null;
  }

  isHidden(tabItem: PermissionItem) {
    if (this.permissionsList) {
      const key = this.getFeatureKey(tabItem.key);
      return key ? this.permissionsList[key] === "hidden" : false;
    }
    return false;
  }

  isVisible(featureKey: string) {
    if (featureKey) {
      const key = this.getFeatureKey(featureKey);

      return key &&
        this.permissionsList &&
        (this.permissionsList[key] === "inactive" || this.permissionsList[key] === "hidden")
        ? false
        : true;
    }
    return true;
  }

  isContentVisible(featureKey: string) {
    if (featureKey) {
      const key = this.getFeatureKey(featureKey);
      return key && this.permissionsList && this.permissionsList[key] === "inactive" ? false : true;
    }
    return true;
  }

  getKeyStatus(key: string, list: Record<string, IProfilePagePermissionType> | null) {
    return list ? list[key] || null : null;
  }
}

export const featurePermissions = new FeaturePermissions();

export const getStatus = (
  key: string,
  featureDataList: Record<string, IProfilePagePermissionType> | null
) => {
  const status = featurePermissions.getKeyStatus(key, featureDataList);
  if (status === "inactive") return { type: "grayed-out", label: "Inactive" };
  if (status === "hidden") return { type: "grayed-out", label: "Hidden" };
  if (status === "active") return { type: "primary", label: "Active" };
  return { type: "primary", label: "Active" };
};
