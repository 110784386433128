import { FC, Fragment, useMemo } from "react";

import {
  DesctiptionSection as DescriptionSection,
  NoAccess,
  NoAccessContentLayout,
} from "@/components";
import { ITEM_CONFIG } from "@/constants";
import { featurePermissions } from "@/utils";

import { ChartCard } from "./components/ChartCard/ChartCard";

import styles from "./MarketView.module.scss";

export const MarketView = () => {
  const charts = useMemo(() => {
    return ITEM_CONFIG.filter(({ featureKey }) => featurePermissions.isVisible(featureKey));
  }, [ITEM_CONFIG]);

  return (
    <NoAccessContentLayout title="Market View" isContentVisible={charts.length > 0}>
      <div className={styles.container}>
        <section className={styles.inner}>
          {charts.map((config) => {
            return <ChartCard key={config.id} data={config} />;
          })}
        </section>

        <DescriptionSection />
      </div>
    </NoAccessContentLayout>
  );
};
