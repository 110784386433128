import { useCallback, useEffect, useMemo } from "react";

import { paginator } from "@/constants";
import { useAppDispatch, useAppSelector } from "@/hooks";
import {
  getPortfolioList,
  managePortfolioState,
  setPage,
  setPerPage,
} from "@/store/portfolios/portfolio";
import { profileInfo } from "@/store/profile";

export const useMyPortfolios = () => {
  const { portfolioList, isLoading, page, perPage, copiedTemplateId } =
    useAppSelector(managePortfolioState);
  const { profile } = useAppSelector(profileInfo);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (portfolioList.length) return;
    dispatch(getPortfolioList());
  }, [dispatch, portfolioList.length]);

  const selectPageHandler = useCallback((page: number) => {
    dispatch(setPage(page));
  }, []);

  const selectPerPageHandler = useCallback((perPage: number) => {
    dispatch(setPage(1));
    dispatch(setPerPage(perPage));
  }, []);

  const portfoliosPaginator = useMemo(() => {
    return {
      page,
      perPage,
      setPage: selectPageHandler,
      setPerPage: selectPerPageHandler,
      perPageList: paginator.perPageList,
    };
  }, [selectPageHandler, selectPerPageHandler, page, perPage]);

  const myPortfolios = useMemo(
    () => portfolioList.filter(({ user_id }) => profile?.id === user_id),
    [portfolioList, profile?.id]
  );

  return {
    copiedTemplateId,
    portfoliosPaginator,
    portfolioList: myPortfolios,
    isLoading,
  };
};
