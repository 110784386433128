import React, { Fragment, useState } from "react";

import { Loader } from "@components/Loader/Loader";

import { useOuterClick } from "@/hooks";

import { useDetectEmptySpace } from "./hooks/useDetectEmptySpace";

import styles from "./Dropdown.module.scss";

const DEFAULT_VISIBLE_ROWS = 7;
const DEFAULT_ITEM_HEIGHT = 36;

export type ItemType<U extends string> = {
  key: U;
  value: string;
  id?: number;
  subItems?: {
    key: U;
    value: string;
  }[];
  replaceWith?: string;
  component?: JSX.Element;
};

interface IDropdownProps<T, K extends string> {
  list: ItemType<K>[];
  onSelect: (name: T, item: ItemType<K>) => void;
  actionElement?: () => JSX.Element | null;
  selected: ItemType<K> | null;
  label: string;
  name: T;
  disable?: boolean;
  placeholder?: string;
  loading?: boolean;
  isRequired?: boolean;
  icon?: string;
  visibleRows?: number;
  isReadonly?: boolean;
}

export function Dropdown<T, K extends string>({
  list,
  onSelect,
  actionElement,
  selected,
  label,
  name,
  disable,
  placeholder,
  loading,
  isRequired,
  isReadonly = false,
  icon,
}: IDropdownProps<T, K>) {
  const [toggle, setToggle] = useState(false);
  const [itemRef] = useOuterClick(setToggle, false);

  const selectHandler = (item: ItemType<K>, isSubitem = false) => {
    if (!isSubitem) {
      onSelect(name, item);
      setToggle(false);
    }
  };

  const getActiveStyle = (item: ItemType<K>) => {
    return selected?.key === item.key ? styles.active : "";
  };

  const toggleHandle = (ev: React.MouseEvent) => {
    ev.stopPropagation();
    setToggle((prev) => !prev);
  };

  const selectedItemText = list.reduce((acc, i) => {
    if (i.subItems) {
      const sub = i.subItems.find((subItem) => subItem.key === selected?.key);
      if (sub) acc = `${i.value} / ${selected?.value}`;
    }
    if (i.key === selected?.key) acc = selected.value;
    return acc;
  }, placeholder || "Select");

  const { listRef, isListOverflow } = useDetectEmptySpace({
    count: list.length,
    visibleRows: DEFAULT_VISIBLE_ROWS,
    itemHeight: DEFAULT_ITEM_HEIGHT,
  });

  const hasLabel = label;
  const isListEmpty = list.length === 0;

  return (
    <div
      className={`${styles.container} ${disable || loading ? styles.disable : ""} ${
        toggle && !loading ? styles.open : ""
      } ${loading ? styles.loading : ""}`}
      data-readonly={isReadonly}
    >
      <div ref={itemRef}>
        <div className={styles.labelInner}>
          {!!label && (
            <span className={`${styles.label} ${isRequired ? styles.required : ""}`}>{label}</span>
          )}
          {actionElement ? actionElement() : null}
        </div>

        <div className={styles.dropdownContainer} onClick={toggleHandle} ref={listRef}>
          {icon && (
            <div className={styles.iconContainer}>
              <img src={icon} alt="icon" />
            </div>
          )}
          {loading ? (
            <div className={styles.loadingWrapper}>
              <Loader />
            </div>
          ) : (
            <span className={`${styles.selectedItem} ${selected ? styles.selected : ""}`}>
              {selectedItemText}
            </span>
          )}
        </div>
        <div
          className={`${styles.listContainer} ${isListOverflow ? styles.reversePosition : ""} ${
            hasLabel ? "" : styles.withoutLabel
          }`}
        >
          <div className={styles.wrapper}>
            <ul className={styles.list}>
              {isListEmpty ? (
                <Fragment>
                  <li className={`${styles.listItem} ${styles.empty}`}>List is Empty</li>
                </Fragment>
              ) : (
                <Fragment>
                  {list.map((item) => (
                    <Fragment key={item.key}>
                      <li
                        className={`${styles.listItem} ${getActiveStyle(item)} ${
                          item?.subItems ? styles.subitemsLablel : ""
                        }`}
                        onClick={() => selectHandler(item, !!item?.subItems)}
                        style={{ height: DEFAULT_ITEM_HEIGHT }}
                      >
                        <span className={styles.listItemLabel}>{item.value}</span>
                        {item.component ? (
                          <span className={styles.componentWrapper}>{item.component}</span>
                        ) : null}
                      </li>
                      {item.subItems && (
                        <div key={item.key + "-subitem"}>
                          {item.subItems.map((subitem) => (
                            <li
                              key={subitem.key}
                              className={`${styles.subItem} ${
                                selected?.value === subitem.value ? styles.active : ""
                              }`}
                              onClick={() => selectHandler(subitem)}
                            >
                              - {subitem.value}
                            </li>
                          ))}
                        </div>
                      )}
                    </Fragment>
                  ))}
                </Fragment>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
