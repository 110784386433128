import { FC, Fragment, useMemo } from "react";

import {
  Button,
  Chips,
  ChipsType,
  Dropdown,
  ILabelItem,
  ItemType,
  LoaderWrapper,
  RadioList,
} from "@/components";
import { productList } from "@/constants";
import { IProfilePagePermissionType, IUser } from "@/types";
import { featurePermissions, getStatus } from "@/utils";

import { useGetUserAccessStatus } from "../../hooks/useGetUserAccessStatus";
import { useSelectPageAccessProduct } from "../../hooks/useSelectPageAccessProduct";
import { useUpdateAccessConfig } from "../../hooks/useUpdateAccessConfig";
import { useUserAccessFeatures } from "../../hooks/useUserAccessFeatures";

import styles from "./UserPermissions.module.scss";

interface IProps {
  selectedUser: IUser | null;
}

export const UserPermissions: FC<IProps> = ({ selectedUser }) => {
  const email = selectedUser?.email || "";
  const { setAccessConfig, setProgress, accessConfig, configRef, progress, canSaveConfig } =
    useGetUserAccessStatus(selectedUser);

  const { selectProductHandle, selectStatusHandle, activeStatus, statusList, product } =
    useSelectPageAccessProduct(setAccessConfig, accessConfig);

  const { saveHandle } = useUpdateAccessConfig(accessConfig, configRef, email, setProgress);

  const {
    loading,
    resource,
    feature,
    featuresList,
    resourcesList,
    canSaveFeatures,
    activeFeatureStatus,
    featureDataList,
    // activeResourceStatus,

    // selectResourceHandle,
    // selectResourceStatusHandle,
    saveFeaturesHandle,
    selectFeatureHandle,
    selectFeatureStatusHandle,
  } = useUserAccessFeatures(selectedUser?.email || null);

  const saveAccessHandle = () => {
    if (canSaveConfig) saveHandle();
    if (canSaveFeatures) saveFeaturesHandle(selectedUser?.email || null);
  };

  const featuresListWithStatus = useMemo(() => {
    return featuresList.map((i) => {
      const { type, label } = getStatus(i.key, featureDataList);
      i.component = <Chips label={label} size="small" type={type as ChipsType} />;
      return i;
    });
  }, [featuresList, featureDataList]);

  const productListWithStatus = useMemo(() => {
    return productList.map((i) => {
      const { type, label } = getStatus(i.key, accessConfig);
      i.component = <Chips label={label} size="small" type={type as ChipsType} />;
      return i;
    });
  }, [productList, accessConfig]);

  return (
    <div className={styles.container} style={{ minHeight: progress ? 240 : "auto" }}>
      <LoaderWrapper loading={progress} transparent>
        <div className={styles.inner}>
          <div className={styles.fieldRow}>
            <p className={styles.groupLabel}>Change permissions by Product</p>
            <ProductFieldsRow
              statusList={statusList}
              productList={productListWithStatus}
              onSelectProduct={selectProductHandle}
              onSelectStatus={selectStatusHandle}
              product={product}
              status={activeStatus}
              placeholder="Forecast, Modeling ..."
              label="Product"
              name="product"
            />
          </div>

          <div className={styles.fieldRow}>
            <p className={styles.groupLabel}>Change permissions by Features</p>
            <ProductFieldsRow
              statusList={statusList}
              productList={featuresListWithStatus}
              onSelectProduct={selectFeatureHandle}
              onSelectStatus={selectFeatureStatusHandle}
              product={feature}
              status={activeFeatureStatus}
              placeholder="Multi-Assets, Equity Sectors..."
              label="Feature"
              name="feature"
            />
          </div>
          {/* <div className={styles.fieldRow}>
            <p className={styles.groupLabel}>Change permissions by Resources</p>
            <ProductFieldsRow
              statusList={statusList}
              productList={productList}
              onSelectProduct={selectResourceHandle}
              onSelectStatus={selectResourceStatusHandle}
              product={resource}
              status={activeResourceStatus}
              placeholder="Brochure guide, Asset Allocation guide..."
              label="Resource"
              name="resource"
            />
          </div> */}
        </div>
      </LoaderWrapper>

      <div className={styles.bntWrapper}>
        <Button
          text="Save Changes"
          type="primary"
          onClick={saveAccessHandle}
          disabled={!(canSaveConfig || canSaveFeatures) || progress || loading}
        />
      </div>
    </div>
  );
};

interface IProductFieldsRowProps {
  statusList: ILabelItem<string>[];
  productList: ItemType<string>[];
  onSelectStatus: (name: string) => void;
  onSelectProduct: (name: string, item: ItemType<string>) => void;
  product: ItemType<string> | null;
  status: ItemType<IProfilePagePermissionType> | null;
  label?: string;
  flow?: "column" | "row";
  placeholder?: string;
  name: string;
}

export const ProductFieldsRow: FC<IProductFieldsRowProps> = ({
  onSelectStatus,
  onSelectProduct,
  product,
  status,
  statusList,
  productList,
  label = "",
  flow = "row",
  placeholder = "Select option...",
  name,
}) => {
  return (
    <Fragment>
      <div className={styles.group} data-flow={flow}>
        <div className={styles.itemWrapper}>
          <Dropdown<string, string>
            name={`${name}-product`}
            onSelect={onSelectProduct}
            list={productList}
            selected={product}
            placeholder={placeholder}
            label={label}
          />
        </div>
        <div className={styles.statusGroup}>
          <RadioList
            name={`${name}-status`}
            labels={statusList}
            value={status?.key || ""}
            onChange={onSelectStatus}
            disabled={product === null}
          />
        </div>
      </div>
    </Fragment>
  );
};
