import { FC } from "react";

import { notification } from "@/utils";

import styles from "./CopyLink.module.scss";

import { ReactComponent as LinkIcon } from "@images/copy.svg";

interface IProps {
  link?: string;
  children: React.ReactNode;
}

export const CopyLink: FC<IProps> = ({ link, children }) => {
  const copyAction = () => {
    navigator.clipboard.writeText(link || "");
    notification.info("Ticker successful copied.");
  };
  return (
    <p className={styles.container}>
      <span>{children}</span>
      <span className={styles.link} onClick={copyAction}>
        <LinkIcon />
      </span>
    </p>
  );
};
