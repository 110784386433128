import { FC, Fragment } from "react";

import { MyStrategies } from "./components";
import { TemplateStrategies } from "./components/TemplateStrategies/TemplateStrategies";

import styles from "./StrategiesDashboard.module.scss";

export const StrategiesDashboard: FC = () => {
  return (
    <Fragment>
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <MyStrategies />
          <TemplateStrategies />
        </div>
      </div>
    </Fragment>
  );
};
