import React, { FC, Fragment, useEffect, useState } from "react";

import { Button, FooterLayout, Loader } from "@/components";
import { getPDF } from "@/features/Resources/components/Documents/Documents";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { AdminDocumentsState, getDocuments } from "@/store/adminPanel/documents";

import { InfoHeading } from "../Heading/Heading";

import styles from "./NoAccess.module.scss";

import { ReactComponent as PermissionImage } from "@images/permissions.svg";

interface IProps {
  title: string;
}

const BROCHURE_DOC = "OdinUltra_Brochure";

export const NoAccess: FC<IProps> = ({ title }) => {
  return (
    <FooterLayout showFooter={false}>
      <div className={styles.container}>
        <div className={styles.inner}>
          <InfoHeading>{title}</InfoHeading>
          <BlockedContent asInnerContent={true} />
        </div>
      </div>
    </FooterLayout>
  );
};

interface INoAccessLayoutProps {
  isContentVisible: boolean;
  children: React.ReactNode;
  title?: string;
}

export const NoAccessTabLayout: FC<INoAccessLayoutProps> = ({ isContentVisible, children }) => {
  return (
    <Fragment>{isContentVisible ? children : <BlockedContent asInnerContent={true} />}</Fragment>
  );
};

export const NoAccessContentLayout: FC<INoAccessLayoutProps> = ({
  isContentVisible,
  children,
  title = "Content",
}) => {
  return <Fragment>{isContentVisible ? children : <NoAccess title={title} />}</Fragment>;
};

export const BlockedContent: FC<{ asInnerContent?: boolean }> = ({ asInnerContent = false }) => {
  const { documents, isLoading } = useAppSelector(AdminDocumentsState);
  const [progress, setProgress] = useState(false);
  const dispatch = useAppDispatch();

  const uploadDocument = async (name: string, id: number) => {
    await getPDF(name, id);
    setProgress(false);
  };

  const getAccessHandle = () => {
    const document = documents?.find((d) => d.key === BROCHURE_DOC);

    if (document) {
      uploadDocument(BROCHURE_DOC, document.id);
      setProgress(true);
    }
  };

  useEffect(() => {
    if (!documents || documents?.length === 0) {
      dispatch(getDocuments());
    }
  }, [documents]);

  return (
    <div className={styles.content} data-content={asInnerContent}>
      <p className={styles.mainTitle}>Unfortunately, you cannot view this content.</p>
      <p className={styles.subTitle}>
        Your profile does not have access to this module of the product.
      </p>
      <p className={styles.info}>
        For detailed information about how to subscribe to this feature, please{" "}
        <a href="mailto:support@odinultra.ai">contact us.</a>
      </p>
      <div className={styles.buttonWrapper}>
        {isLoading || progress ? (
          <Loader />
        ) : (
          <Button text="Learn More" type="secondary" onClick={getAccessHandle} />
        )}
      </div>
      <PermissionImage />
    </div>
  );
};
