import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";

import { ItemType } from "@/components";
import { featuresGlobalList, statusList } from "@/constants";
import { AdminPanelService, pagesPermissionService } from "@/services";
import { IProfilePagePermissionType, IUser, IUserGlobalAccessConfig } from "@/types";
import { featurePermissions, notification } from "@/utils";

const featuresList = featuresGlobalList.map(
  ({ key, value }): ItemType<string> => ({
    key,
    value,
  })
);

export const useUserAccessFeatures = (email: string | null) => {
  const [feature, setFeature] = useState<ItemType<string> | null>(null);
  const [resource, setResource] = useState<ItemType<string> | null>(null);
  const [loading, setLoading] = useState(false);
  const [resourcesList, setResourcesList] = useState<{ key: string; value: string }[]>([]);
  const [featureDataList, setFeatureDataList] = useState<Record<
    string,
    IProfilePagePermissionType
  > | null>(null);
  // const [resourcesDataList, setResourcesDataList] = useState<IResourcesAccess | null>(null);
  const globalConfig = useRef<IUserGlobalAccessConfig | null>(null);

  const [activeFeatureStatus, setActiveFeatureStatus] = useState<{
    key: IProfilePagePermissionType;
    value: string;
  } | null>(null);

  // const [activeResourceStatus, setActiveResourceStatus] = useState<{
  //   key: IProfilePagePermissionType;
  //   value: string;
  // } | null>(null);

  // const email = selectedUser?.email || null;

  const fetchUserFeaturesAccessData = useCallback(async (email: string) => {
    try {
      const { data } = await pagesPermissionService.getUserGlobalAccessStatus(email);
      const tutorialsResult = await AdminPanelService.getPages();
      const featuresList = featurePermissions.createFeaturesPermissionsList(data.result);
      // const resourcesList = featurePermissions.createResourcesPermissionsList(data.result);
      // const resourcesList = featurePermissions.createResourcesPermissionsList(data.result);

      globalConfig.current = data.result;
      // setResourcesList([]);
      // setResourcesDataList(resourcesList);
      setFeatureDataList(featuresList);
    } catch (e) {
      //
    } finally {
      setLoading(false);
    }
  }, []);

  const selectFeatureHandle = useCallback(
    (name: string, item: ItemType<string>) => {
      setFeature(item);
      if (featureDataList && featureDataList[item.key]) {
        const status = statusList.find((listItem) => listItem.key === featureDataList[item.key]);
        setActiveFeatureStatus(status ? status : null);
        return;
      }
      // set active status as default
      setActiveFeatureStatus(statusList[0]);
    },
    [featureDataList]
  );

  // const selectResourceHandle = useCallback(() => {
  //   //
  // }, []);

  // const selectResourceStatusHandle = useCallback(() => {
  //   //
  // }, []);

  const selectFeatureStatusHandle = useCallback(
    (name: string) => {
      const item = statusList.find((status) => status.key === name) || null;
      const accessState = item?.key as IProfilePagePermissionType | null;
      setActiveFeatureStatus(item);
      if (feature && accessState)
        setFeatureDataList((prev) => ({ ...prev, [feature.key]: accessState }));
    },
    [feature]
  );

  const saveFeaturesHandle = useCallback(
    (userEmail: string | null) => {
      const saveAccessData = async (config: IUserGlobalAccessConfig, userEmail: string) => {
        try {
          const { data } = await pagesPermissionService.updateGlobalAccessStatus(config, userEmail);
          notification.success("User features access status has updated.");
          globalConfig.current = config;
          setFeatureDataList({ ...featureDataList });
        } catch (err) {
          notification.error("Has error during saving the user access status.");
        }
      };

      if (featureDataList && userEmail) {
        const accessConfig = featurePermissions.createFeatureConfig(
          featureDataList,
          globalConfig.current
        );
        saveAccessData(accessConfig, userEmail);
      }
    },
    [featureDataList, globalConfig]
  );

  useLayoutEffect(() => {
    if (email) {
      fetchUserFeaturesAccessData(email);
      setLoading(true);
    }
  }, [email]);

  const canSaveFeatures = useMemo(() => {
    if (globalConfig.current === null && featureDataList === null) return true;
    try {
      const configString = JSON.stringify(globalConfig.current?.config?.features || null);
      const featuresString = JSON.stringify(featureDataList);
      return configString !== featuresString;
    } catch {
      console.log("Error of compare objects!");
      return true;
    }
  }, [featureDataList]);

  return {
    loading,
    feature,
    resource,
    featuresList,
    resourcesList,
    featureDataList,
    canSaveFeatures,
    activeFeatureStatus,
    // activeResourceStatus,

    // selectResourceHandle,
    // selectResourceStatusHandle,
    saveFeaturesHandle,
    selectFeatureHandle,
    selectFeatureStatusHandle,
  };
};
