import { FC } from "react";

import { CommonStartegies } from "@/features/StrategiesDashboard";

import { MyPortfolios } from "../components";
import { TemplatePortfolios } from "../components/TemplatePortfolios/TemplatePortfolios";

import styles from "./Portfolio.module.scss";

export const PortfolioMain: FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <MyPortfolios />
      </div>
      <div className={styles.section}>
        <TemplatePortfolios />
      </div>

      <CommonStartegies />
    </div>
  );
};
