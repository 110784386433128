import { FC, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Checkbox,
  Filter,
  Heading,
  IMenuItem,
  InputItem,
  LoaderWrapper,
  Pagination,
} from "@/components";
import { ROUTES } from "@/constants";
import { useAppDispatch, usePagination, useSearchTextInList } from "@/hooks";
import { fetchCopyItem } from "@/store/strategies/my_strategies";
import { IStartegyCard } from "@/types";

import { useFilter } from "./hooks/useFilter";
import { useMyStrategies } from "./hooks/useMyStrategies";
import { StrategiesList } from "../../StrategiesList/StrategiesList";

import styles from "./MyStrategies.module.scss";

import { ReactComponent as ResetIcon } from "@images/reload.svg";

interface IProps {
  isFavorites?: boolean;
}

export const MyStrategies: FC<IProps> = ({ isFavorites = false }) => {
  const { strategiesList, strategiesPaginator, isLoading, copiedTemplateId } = useMyStrategies();
  const { page, perPage, setPage, setPerPage, perPageList } = strategiesPaginator;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const listLenRef = useRef(1);

  const selectHandler = useCallback((item: IStartegyCard) => {
    navigate(
      `${ROUTES.strategiesBuilder.path}/edit/${item.id}?name=${encodeURIComponent(item.name)}`
    );
  }, []);

  const selectMenuHandler = useCallback((menuItem: IMenuItem, item: IStartegyCard) => {
    if (menuItem.type === "copy") {
      dispatch(fetchCopyItem(item));
      setPage(listLenRef.current);
    }
    if (menuItem.type === "edit") {
      navigate(`${ROUTES.strategiesBuilder.path}/edit/${item.id}`);
    }
    if (menuItem.type === "view") {
      navigate(`${ROUTES.strategiesPreview.path}/${item.id}?name=${encodeURIComponent(item.name)}`);
    }
  }, []);

  const {
    filteredList: searchFiltered,
    searchText,
    handleSearch,
    handleEnter,
  } = useSearchTextInList(strategiesList, setPage);

  const {
    resetFilterHandle,
    changeCheckHandle,

    isFilterChecked,
    filteredList,
    filter,
  } = useFilter(searchFiltered);

  const { totalPages, paginatedList } = usePagination<IStartegyCard>({
    list: filteredList,
    page,
    perPage,
  });

  listLenRef.current = totalPages || 1;

  return (
    <LoaderWrapper loading={isLoading} fullWidth>
      <div className={styles.container}>
        <div className={styles.headingGroup}>
          <Heading type="h3" className={styles.subHeading}>
            My Strategies
          </Heading>
        </div>
        <div className={styles.searchWrapper}>
          <InputItem
            onChange={handleSearch}
            onEnter={handleEnter}
            label=""
            name="strategy-search"
            type="search"
            placeholder="Search Strategy"
            noPaddingBottom
          />

          <Filter active={isFilterChecked}>
            <p className={styles.filterTitle}>Trading Instrument</p>
            <div className={styles.filterCheckList}>
              <Checkbox
                id="stockEtfs"
                name="stockEtfs"
                label="Stocks/ETFs"
                checked={filter.stockEtfs}
                onChange={changeCheckHandle}
              />
              <Checkbox
                id="baskets"
                name="baskets"
                label="Baskets"
                checked={filter.baskets}
                onChange={changeCheckHandle}
              />
              <Checkbox
                id="options"
                name="options"
                label="Options"
                checked={filter.options}
                onChange={changeCheckHandle}
              />
              <Checkbox
                id="cryptos"
                name="cryptos"
                label="Cryptos"
                checked={filter.cryptos}
                onChange={changeCheckHandle}
              />
            </div>
            <p className={styles.filterTitle}>Strategy Type</p>
            <div className={styles.filterCheckList}>
              <Checkbox
                id="typeCore"
                name="typeCore"
                label="Core"
                checked={filter.typeCore}
                onChange={changeCheckHandle}
              />
              <Checkbox
                id="typeOverlay"
                name="typeOverlay"
                label="Overlay"
                checked={filter.typeOverlay}
                onChange={changeCheckHandle}
              />
            </div>
            <div className={styles.filterButtonWrapper}>
              <Button
                text="Reset to Default"
                type="secondary"
                IconComponent={ResetIcon}
                onClick={resetFilterHandle}
              />
            </div>
          </Filter>
        </div>

        <div className={`${styles.wrapper} ${isFavorites ? styles.noPadding : ""}`}>
          <div className={styles.paginationContainer}>
            <Pagination
              count={filteredList?.length || 0}
              perPageList={perPageList}
              totalCount={totalPages}
              perPage={perPage}
              currentPage={page}
              onChangePerPage={setPerPage}
              onPage={setPage}
              countLabel="Strategies"
            />
          </div>
          <StrategiesList
            isFavorites={isFavorites}
            strategiesList={paginatedList}
            selectHandler={selectHandler}
            selectMenuHandler={selectMenuHandler}
            searchText={searchText}
            copiedTemplateId={copiedTemplateId}
          />
        </div>
      </div>
    </LoaderWrapper>
  );
};
