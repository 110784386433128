import dayjs from "dayjs";
import { FC, useCallback, useEffect, useState } from "react";

import { CreateNew, IMenuItem } from "@/components";
import { DATE_ISO, ROUTES } from "@/constants";
import { useDownloadLivePositions } from "@/features/StrategyCreate";
import { ReactComponent as ViewPerformanceIcon } from "@/images/chart-histogram-two-filled.svg";
import { ReactComponent as CopyIcon } from "@/images/copy.svg";
import { IStartegyCard } from "@/types";

import { StrategyItem } from "../components";
import { EmptySearch } from "../components/Search/Search";

import styles from "./StrategiesList.module.scss";

interface IStrtaegiesListProps {
  isFavorites: boolean;
  strategiesList: IStartegyCard[];
  selectHandler: (item: IStartegyCard) => void;
  selectMenuHandler: (menuItem: IMenuItem, item: IStartegyCard) => void;
  searchText?: string;
  isTemplates?: boolean;
  copiedTemplateId?: number | null;
}

const MENU_ITEMS: IMenuItem[] = [
  {
    icon: <CopyIcon />,
    type: "copy",
    label: "Copy",
    key: "1",
  },
  {
    icon: <ViewPerformanceIcon style={{ color: "var(--primary)" }} />,
    type: "view",
    label: "Model",
    key: "2",
    disabled: false,
  },
];

export const StrategiesList: FC<IStrtaegiesListProps> = ({
  isFavorites,
  strategiesList,
  selectHandler,
  selectMenuHandler,
  searchText,
  isTemplates = false,
  copiedTemplateId = null,
}) => {
  const [downloadCSV, progress] = useDownloadLivePositions();
  const [progressId, setProgressId] = useState<number | null>(null);

  const downloadLivePositionHandler = useCallback((item: IStartegyCard) => {
    const fileName = `${item.name}_live_position_${dayjs().format(DATE_ISO)}`;
    downloadCSV(item.id, fileName);
    setProgressId(item.id);
  }, []);

  const getMenuActions = useCallback(
    (item: IStartegyCard) => {
      if (isTemplates) return MENU_ITEMS.slice(0, 1);
      return MENU_ITEMS.filter(
        ({ type }) =>
          !((isFavorites && type === "copy") || (!item.permissions?.has_write && type === "edit"))
      );
    },
    [isFavorites, isTemplates]
  );

  useEffect(() => {
    if (!progress) setProgressId(null);
  }, [progress]);

  return (
    <div className={styles.innerList}>
      {searchText && strategiesList.length === 0 ? <EmptySearch /> : null}
      <div className={styles.listWrapper}>
        {!isFavorites && !isTemplates && !searchText ? (
          <div className={styles.createNewItem}>
            <CreateNew
              className={styles.newItem}
              to={ROUTES.strategyCreate.path}
              text="Create New Strategy"
            />
          </div>
        ) : null}
        {strategiesList?.map((strategy, idx) => (
          <StrategyItem
            idx={idx}
            item={strategy}
            onSelect={selectHandler}
            onPositionDownload={downloadLivePositionHandler}
            key={`${strategy.id}-${idx}`}
            style={{ maxWidth: "100%", minWidth: "100%" }}
            isFavorites={isFavorites}
            isTemplate={isTemplates}
            onSelectMenu={selectMenuHandler}
            progress={progressId === strategy.id}
            disabledDownload={progress}
            searchText={searchText}
            menuActions={getMenuActions(strategy)}
            copiedTemplateId={copiedTemplateId === strategy.id ? copiedTemplateId : null}
            hasMenu
          />
        ))}
      </div>
    </div>
  );
};
