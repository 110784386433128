import { FC } from "react";
import Select, { components, MenuListProps, Options, Props } from "react-select";
import { FixedSizeList as List } from "react-window";

import { SelectOptionType, SelectValueType } from "@/types";

import styles from "./CustomeSelect.module.scss";

type BaseSelectProps = Pick<
  Props<SelectOptionType, boolean>,
  Exclude<keyof Props, "value" | "onChange" | "isDisabled">
>;

interface IProps extends BaseSelectProps {
  label?: string;
  value?: SelectValueType | SelectValueType[];
  onChange(value: SelectValueType | SelectValueType[] | null): void;
  noPaddingBottom?: boolean;
  isDisabled?: boolean;
  isReadonly?: boolean;
}

export const CustomSelect: FC<IProps> = (props) => {
  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: "inherit",
      borderColor: state.isFocused ? "#013789" : "#013789",
      outline: state.isFocused ? "1.5px solid" : "1px",
      boxShadow: state.isFocused ? null : null,
      padding: "0 6px",
      "&:hover": {
        borderColor: state.isFocused ? "#013789" : "#013789",
        outline: state.isFocused ? "1.5px solid" : "1px",
      },
      color: "var(--primary)",
      minHeight: "41px",
      opacity: props.isReadonly ? "0.5" : 1,
      pointerEvents: props.isReadonly ? "none" : "all",
    }),
    placeholder: (provided: any, state: any) => ({
      ...provided,
      color: "rgba(1, 66, 148, 0.4)",
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      svg: {
        fill: "#014294",
      },
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      display: "none",
    }),
    input: (provided: any) => ({
      ...provided,
      color: "var(--primary",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: "var(--primary",
    }),
  };
  const baseProps: any = {
    ...props,
  };

  if (props.isMulti) {
    const displayValues = props.value
      ? (props.value as SelectValueType[]).map((valueItem: SelectValueType) => ({
          value: valueItem,
          label:
            props.options?.find((option) => (option as SelectOptionType).value === valueItem)
              ?.label || valueItem,
        }))
      : null;
    baseProps.value = displayValues;
    baseProps.onChange = (selectedOptions: SelectOptionType[]) =>
      props.onChange(selectedOptions.map((option) => option.value));
    baseProps.hideSelectedOptions = props?.hideSelectedOptions ?? false;
  } else {
    const displayValue =
      props.value !== undefined && props.value !== null
        ? props.options?.find((option) => (option as SelectOptionType).value === props.value) || {
            label: props.value,
            value: props.value,
          }
        : null;
    baseProps.value = displayValue;
    baseProps.onChange = (selectedOption: SelectOptionType) =>
      selectedOption ? props.onChange(selectedOption.value) : props.onChange(null);
  }

  const MenuList: FC<any> = (props) => {
    const { options, children, maxHeight, getValue } = props;
    const height = 35;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;
    if (children?.length < 100) {
      return <components.MenuList {...props}>{props.children}</components.MenuList>;
    }
    return (
      <List
        width="auto"
        height={maxHeight}
        itemCount={children?.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  };

  return (
    <div
      className={`${styles.container} ${props.noPaddingBottom ? "" : styles.paddingBottom} ${
        props.isDisabled ? styles.disabled : ""
      }`}
    >
      {props.label && <label>{props.label}</label>}
      <Select
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: "#cce7ff",
            primary: "#013789",
          },
        })}
        components={{ MenuList }}
        {...baseProps}
        styles={customStyles}
        isDisabled={props.isDisabled}
      />
    </div>
  );
};
