import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { ROUTES, SideBarKeyEnum } from "@/constants";
import { usePreviewPerformanceData } from "@/features/StrategiesDashboard";
import { useAppDispatch, useAppSelector, usePageInfo } from "@/hooks";
import { strategiesService } from "@/services";
import {
  resetStrategy,
  strategiesState,
  fetchPerformancePrefligth,
  fetchBenchmarksList,
  setActiveStrategy,
  fetchMetricsList,
} from "@/store/strategies/strategies";
import {
  fetchTemplateStrategiesData,
  selectTemplateStrategies,
} from "@/store/strategies/template_strategies";
import { IStrategyData } from "@/types";
import { prepareNewStartegyData } from "@/utils";

export const useTemplatePreview = () => {
  const { newStartegyForm, historical } = useAppSelector(strategiesState);
  const { list } = useAppSelector(selectTemplateStrategies);
  const [showModal, setShowModal] = useState({
    backtest: false,
  });
  const [searchParams] = useSearchParams();
  const { templateId } = useParams();
  const sectionRef = useRef<{ result: HTMLElement | null; empty: HTMLElement | null }>({
    result: null,
    empty: null,
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { chartData, tableData, isPreviewReady, isPreviewLoading, columns } =
    usePreviewPerformanceData();

  const isEmpty = useMemo(() => {
    if (!list) return null;
    const foundStrategy = list.find((strategy) => strategy.id === Number(templateId));
    return list && templateId && foundStrategy ? false : true;
  }, [list, templateId]);

  const strategyName = searchParams.get("name");

  const { textInfo, video } = usePageInfo(SideBarKeyEnum.SINGLE_STRATEGY);

  const backHandler = useCallback(() => {
    navigate(`${ROUTES.modelingPortfolioBuilder.path}?current_tab=Single Strategy`);
    strategiesService.abort.abort();
  }, [strategiesService.abort]);

  const selectTabHandler = useCallback((key: string) => {
    //
  }, []);

  const confirmBacktestHandler = useCallback(() => {
    const { config } = prepareNewStartegyData(newStartegyForm);
    if (newStartegyForm.benchmark) config.benchmark = newStartegyForm.benchmark;
    dispatch(fetchPerformancePrefligth({ data: { config } })).finally(() => {
      setShowModal((prev) => ({ ...prev, backtest: false }));
    });
    setShowModal((prev) => ({ ...prev, backtest: true }));
  }, [newStartegyForm]);

  const cancelBacktestHandler = () => {
    setShowModal((prev) => ({ ...prev, backtest: false }));
    strategiesService.abort.abort();
  };

  const isEmptyBacktestingResult = useMemo(
    () => !chartData.some((i) => i.data.length > 0) && !tableData.length && isPreviewReady,
    [tableData, chartData, isPreviewReady]
  );

  const strategyDescription = useMemo(() => {
    if (list && templateId) {
      const foundStrategy = list.find((strategy) => strategy.id === Number(templateId));
      return foundStrategy ? foundStrategy.description : "";
    }
  }, [list, templateId]);

  useEffect(() => {
    if (isEmptyBacktestingResult) {
      sectionRef.current.empty?.scrollIntoView({ block: "center", behavior: "smooth" });
    } else {
      if (isPreviewReady) {
        sectionRef.current.result?.scrollIntoView({ block: "start", behavior: "smooth" });
      }
    }
  }, [isPreviewReady, isEmptyBacktestingResult]);

  useEffect(() => {
    return () => {
      dispatch(resetStrategy());
    };
  }, []);

  useEffect(() => {
    if (newStartegyForm.benchmarkList === null) {
      dispatch(fetchBenchmarksList());
    }
  }, [newStartegyForm.benchmarkList]);

  useLayoutEffect(() => {
    let activeStrategy: IStrategyData | null = null;
    if (list && templateId) {
      const foundStrategy = list.find((strategy) => strategy.id === Number(templateId));
      if (foundStrategy) {
        activeStrategy = foundStrategy;
      }
    }
    if (!list) {
      dispatch(fetchTemplateStrategiesData());
    }
    if (activeStrategy)
      dispatch(fetchMetricsList()).then(() => {
        if (activeStrategy) {
          dispatch(setActiveStrategy(activeStrategy));
        }
      });

    if (activeStrategy) {
      dispatch(setActiveStrategy(activeStrategy));
    }
  }, [list, templateId]);

  return {
    isEmptyBacktestingResult,
    strategyDescription,
    isPreviewLoading,
    newStartegyForm,
    isPreviewReady,
    strategyName,
    sectionRef,
    historical,
    showModal,
    chartData,
    tableData,
    isEmpty,
    columns,

    confirmBacktestHandler,
    cancelBacktestHandler,
    selectTabHandler,
    backHandler,

    textInfo,
    video,
  };
};
