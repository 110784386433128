import { Fragment, memo } from "react";

import { Loader } from "../Loader/Loader";
import { LoaderWrapper } from "../LoaderWrapper/LoaderWrapper";

import styles from "./Tab.module.scss";

export interface ITabItem<U> {
  key: U;
  title: string;
  component: JSX.Element;
  icon?: JSX.Element;
}

interface IProps<U> {
  tabs: ITabItem<U>[];
  selected: string;
  onChange: (key: string) => void;
  loading?: boolean;
  helpLoadingText?: string;
  animatedLoader?: boolean;
}

export function Tab<U extends string>({
  tabs,
  selected,
  onChange,
  loading,
  helpLoadingText,
  animatedLoader,
}: IProps<U>) {
  const tabSelectHandler = (key: string) => {
    onChange(key);
  };
  return (
    <div className={styles.container}>
      <div className={styles.tabsNavigation}>
        {tabs.map(({ key, title, icon }, idx) => (
          <div
            className={`${styles.tabNavItem} ${selected === key ? styles.active : ""}`}
            key={key}
            onClick={() => tabSelectHandler(key)}
          >
            {!!icon && <span className={`${styles.iconContainer}`}>{icon}</span>}
            <span className={styles.label}>{title}</span>
          </div>
        ))}
      </div>
      <div className={styles.tabActiveContainer}>
        {!animatedLoader && (
          <Fragment>
            {loading ? (
              <div className={styles.loaderContainer}>
                <Loader helpText={helpLoadingText} atom />
              </div>
            ) : (
              <Fragment>
                {tabs.map(({ key, component }) =>
                  key === selected ? <Fragment key={key}>{component}</Fragment> : null
                )}
              </Fragment>
            )}
          </Fragment>
        )}

        {animatedLoader && (
          <LoaderWrapper loading={loading || false} fullWidth>
            <Fragment>
              {tabs.map(({ key, component }) =>
                key === selected ? <Fragment key={key}>{component}</Fragment> : null
              )}
            </Fragment>
          </LoaderWrapper>
        )}
      </div>
    </div>
  );
}
