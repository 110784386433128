import { FC, Fragment, useCallback, useEffect, useMemo } from "react";

import { LoaderWrapper } from "@/components";
import { paginator } from "@/constants";
import { MyPortfolios } from "@/features/Portfolio/components";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { selectHome, setHomePage, setHomePerPage } from "@/store/home";
import { getPortfolioList, managePortfolioState } from "@/store/portfolios/portfolio";
import { profileInfo } from "@/store/profile";

interface IProps {
  placeholderComponent: () => JSX.Element;
}

export const FavoritePortfolios: FC<IProps> = ({ placeholderComponent }) => {
  const { portfolioList, isLoading } = useAppSelector(managePortfolioState);
  const { page, perPage } = useAppSelector(selectHome);
  const { profile } = useAppSelector(profileInfo);
  const dispatch = useAppDispatch();

  const filteredPortfoliosList = useMemo(() => {
    return (
      portfolioList?.filter(
        (portfolio) => portfolio.show_on_home && profile?.id === portfolio.user_id
      ) || null
    );
  }, [portfolioList, profile]);

  const selectPageHandler = useCallback((page: number) => {
    dispatch(setHomePage({ portfolios: page }));
  }, []);

  const selectPerPageHandler = useCallback((perpage: number) => {
    dispatch(setHomePage({ portfolios: 1 }));
    dispatch(setHomePerPage({ portfolios: perpage }));
  }, []);

  const paginatorPortfolios = useMemo(() => {
    return {
      page: page.portfolios,
      perPage: perPage.portfolios,
      setPage: selectPageHandler,
      setPerPage: selectPerPageHandler,
      perPageList: paginator.perPageHomeList,
    };
  }, [selectPageHandler, selectPerPageHandler, page, perPage]);

  const PlaceHolder = placeholderComponent;

  const isEmptyList = filteredPortfoliosList.length === 0;

  useEffect(() => {
    if (portfolioList.length) return;
    dispatch(getPortfolioList());
  }, [dispatch, portfolioList.length]);

  return (
    <Fragment>
      {isEmptyList && isLoading === false ? (
        <PlaceHolder />
      ) : (
        <LoaderWrapper loading={isLoading} atom fullWidth animatedHeight>
          <MyPortfolios />
        </LoaderWrapper>
      )}
    </Fragment>
  );
};
