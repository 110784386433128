import { FC } from "react";

import { Heading, InputItem, LoaderWrapper, NoData, Pagination } from "@/components";
import { ROUTES } from "@/constants";
import { EmptySearch } from "@/features/StrategiesDashboard/components/Search/Search";
import { usePagination, useSearchTextInList } from "@/hooks";
import { IPortfolio } from "@/types";

import { useTemplatePortfolios } from "../hooks/useTemplatePortfolios";
import { PortfolioItem } from "../PortfolioItem/PortfolioItem";

import styles from "./TemplatePortfolios.module.scss";

interface IProps {
  name?: string;
}

export const TemplatePortfolios: FC<IProps> = () => {
  const { portfoliosPaginator, portfolioList, isLoading } = useTemplatePortfolios();
  const { page, perPage, setPage, setPerPage, perPageList } = portfoliosPaginator;

  const { filteredList, searchText, handleSearch, handleEnter } = useSearchTextInList(
    portfolioList || [],
    setPage
  );

  const { totalPages, paginatedList } = usePagination<IPortfolio>({
    list: filteredList,
    page,
    perPage,
  });

  return (
    <LoaderWrapper loading={isLoading} fullWidth>
      <div className={styles.container}>
        <div className={styles.headingGroup}>
          <Heading type="h3" className={styles.subHeading}>
            Template Portfolios
          </Heading>
        </div>
        <div className={styles.searchWrapper}>
          <InputItem
            onChange={handleSearch}
            onEnter={handleEnter}
            label=""
            name="strategy-search"
            type="search"
            placeholder="Search Portfolio"
            noPaddingBottom
          />
        </div>
        <div className={`${styles.wrapper}`}>
          {searchText && paginatedList.length === 0 ? (
            <NoData text="No portfolio templates were found based on your search." />
          ) : null}
          <div className={styles.paginationContainer}>
            <Pagination
              onPage={setPage}
              onChangePerPage={setPerPage}
              perPage={perPage}
              currentPage={page}
              totalCount={totalPages}
              count={portfolioList?.length || 0}
              perPageList={perPageList}
              countLabel={"Total Templates"}
            />
          </div>
          {paginatedList.length > 0 ? (
            <div className={styles.inner}>
              <div className={styles.listWrapper}>
                {paginatedList?.map((portfolio, idx) => (
                  <PortfolioItem
                    key={portfolio.id}
                    portfolio={portfolio}
                    searchText={searchText}
                    idx={idx}
                    link={`${ROUTES.portfolioTemplatePreview.path}/${portfolio.id}`}
                    isTemplate
                    isMyPortfolio
                    hasMenu
                  />
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </LoaderWrapper>
  );
};
