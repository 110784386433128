import { FC, Fragment, useRef } from "react";

import { DropdownMultiSearch } from "@components/Dropdown/DropdownMultiSearch";

import { Dropdown, InputItem, TooltipWrapper } from "@/components";
import {
  directionList,
  rebalanceFrequencyList,
  tradingBasketList,
  updateFrequencyList,
} from "@/constants";
import {
  ITickersKeyValueData,
  RebalanceFrequencyType,
  UpdateFrequencyType,
  WeightingScheme,
} from "@/types";

import { useBasketsForm } from "../../hooks/useBasketsForm";

import styles from "./BasketForm.module.scss";

import { ReactComponent as UploadIcon } from "@images/upload-icon.svg";

interface IProps {
  tradingTickersList: ITickersKeyValueData;
  isLoading: boolean;
  disabled?: boolean;
  isReadonly?: boolean;
}

export const BasketForm: FC<IProps> = ({ tradingTickersList, isLoading, disabled, isReadonly }) => {
  const {
    tradingTickers,
    selectedTradingBasket,
    selectedTicker,
    selectedDirection,
    selectedFrequency,
    selectedRebalance,
    selectedWeightScheme,
    disableOtions,
    maxAllowedWeightValue,
    weightingSchemeList,
    isWeightingShemeLoading,
    isWeitingSchemeDisbled,
    isCustomBasket,

    selectDirectionHendler,
    selectTickerHendler,
    slectTradingBasket,
    selectFraquencyHandler,
    selectRebalanceHandler,
    selectWeightHandler,
    uploadTickersHandler,
    changeAllowedWeightHandler,
    blurHandler,
    onGetListHandler,
  } = useBasketsForm(tradingTickersList);

  const TickersLabel = () => {
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const selectFileHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
      const [file] = ev.target.files || [null];
      ev.target.value = "";
      if (file) uploadTickersHandler(file);
    };

    const uploadHandle = () => {
      if (isCustomBasket) {
        fileInputRef.current?.click();
      }
    };

    const selectedTickersList = selectedTicker.map(({ value }) => value).join(", ");

    return (
      <div className={`${styles.customLabel} ${isCustomBasket ? "" : styles.disabled}`}>
        <TooltipWrapper title={"Selected Tickers"} text={selectedTickersList} maxWidth={"100%"}>
          <span className={styles.labelText}>Type List of Tickers</span>
        </TooltipWrapper>
        <TooltipWrapper
          text="The CSV file should contain column 'ticker'."
          title="Upload CSV tickers list"
        >
          <span className={styles.iconWrapper} onClick={uploadHandle}>
            <UploadIcon />
          </span>
        </TooltipWrapper>
        <input
          ref={fileInputRef}
          style={{ display: "none" }}
          type="file"
          onChange={selectFileHandler}
          accept=".csv"
        />
      </div>
    );
  };

  return (
    <Fragment>
      <div className={styles.row}>
        <div className={styles.itemWrapper}>
          <Dropdown
            list={tradingBasketList}
            onSelect={slectTradingBasket}
            selected={selectedTradingBasket}
            placeholder="Select Trading Basket"
            name="basket"
            label="Trading Basket"
            isRequired={true}
            disable={disableOtions || disabled}
            isReadonly={isReadonly}
          />
        </div>
        <div className={styles.itemWrapper}>
          <DropdownMultiSearch<"tickers">
            list={tradingTickers}
            onSelect={selectTickerHendler}
            selected={selectedTicker}
            placeholder="Ticker Name"
            name="tickers"
            disable={
              !selectedTradingBasket ||
              (!!selectedTradingBasket && selectedTradingBasket.key !== "custom-basket") ||
              disabled
            }
            onBlur={onGetListHandler}
            loading={isLoading}
            CustomLabel={TickersLabel}
            visibleRows={10}
            isReadonly={isReadonly}
          />
        </div>
        <div className={styles.itemWrapper}>
          <Dropdown
            list={directionList}
            onSelect={selectDirectionHendler}
            selected={selectedDirection}
            placeholder="Select Direction"
            name="direction"
            label="Strategy Direction"
            disable={disableOtions || disabled}
            isReadonly={isReadonly}
          />
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.itemWrapper}>
          <Dropdown<"frequency", UpdateFrequencyType>
            list={updateFrequencyList}
            onSelect={selectFraquencyHandler}
            selected={selectedFrequency}
            placeholder="Select Update Frequency"
            name="frequency"
            label="Update Frequency"
            disable={disableOtions || disabled}
            isReadonly={isReadonly}
          />
        </div>
        <div className={styles.itemWrapper}>
          <Dropdown<"rebalance", RebalanceFrequencyType>
            list={rebalanceFrequencyList}
            onSelect={selectRebalanceHandler}
            selected={selectedRebalance}
            placeholder="Select Rebalance Frequency"
            name="rebalance"
            label="Rebalance Frequency"
            disable={disableOtions || disabled}
            isReadonly={isReadonly}
          />
        </div>
        <div className={styles.itemWrapper}>
          <Dropdown<"weight", WeightingScheme>
            list={weightingSchemeList}
            onSelect={selectWeightHandler}
            selected={selectedWeightScheme}
            placeholder="Select Weighting Scheme"
            name="weight"
            label="Weighting Scheme"
            disable={isWeitingSchemeDisbled || disabled}
            isRequired={true}
            loading={isWeightingShemeLoading}
            isReadonly={isReadonly}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.itemWrapper}>
          <InputItem<"maxAllowedWeight">
            name={"maxAllowedWeight"}
            placeholder="100"
            label="Max Allowed Weight (%)"
            type="number"
            symbol=""
            value={maxAllowedWeightValue || ""}
            onChange={changeAllowedWeightHandler}
            onBlur={blurHandler}
            disabled={disabled}
            tooltipHelp={"Max Allowed Weight between 0 and 100"}
            isReadonly={isReadonly}
            min={0}
            max={100}
            noPaddingBottom
            isInteger
          />
        </div>
        <div className={styles.empty}></div>
        <div className={styles.empty}></div>
      </div>
    </Fragment>
  );
};
