import { useCallback, useMemo } from "react";

import { MyStrategyFilterType } from "@/constants";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { resetFilter, selectMyStrategies, setFilter } from "@/store/strategies/my_strategies";
import { IStartegyCard } from "@/types";

export const useFilter = (searchFiltered: IStartegyCard[]) => {
  const { filter } = useAppSelector(selectMyStrategies);
  const dispatch = useAppDispatch();

  const isFilterChecked = Object.entries(filter).some(([_, checked]) => checked);

  const filteredList = useMemo(() => {
    const isTypeNotSelected = !filter.typeCore && !filter.typeOverlay;
    const isInstrumentNotSelected = !(
      filter.baskets ||
      filter.cryptos ||
      filter.options ||
      filter.stockEtfs
    );
    const result = isFilterChecked
      ? searchFiltered.filter(
          (el) =>
            ((el.config.type === "core" && filter.typeCore) ||
              (el.config.type === "overlay" && filter.typeOverlay) ||
              isTypeNotSelected) &&
            ((el.config.trading_instrument === "baskets" && filter.baskets) ||
              (el.config.trading_instrument === "cryptos" && filter.cryptos) ||
              (el.config.trading_instrument === "derivatives" && filter.options) ||
              (el.config.trading_instrument === "etfs" && filter.stockEtfs) ||
              (el.config.trading_instrument === "equities" && filter.stockEtfs) ||
              isInstrumentNotSelected)
        )
      : searchFiltered;

    return result;
  }, [searchFiltered, filter, isFilterChecked]);
  const resetFilterHandle = () => {
    dispatch(resetFilter());
  };

  const changeCheckHandle = useCallback((checked: boolean, name?: string) => {
    dispatch(setFilter({ name: name as MyStrategyFilterType, checked }));
  }, []);
  return {
    resetFilterHandle,
    changeCheckHandle,

    isFilterChecked,
    filteredList,
    filter,
  };
};
