import { ItemType } from "@/components";
import { IProfilePagePermissionType } from "@/types";

export const productList: ItemType<string>[] = [
  {
    key: "modeling",
    value: "Modeling Product",
  },
  {
    key: "forecasts",
    value: "Forecast Product",
  },
  {
    key: "odinchat",
    value: "OdinChat Product",
  },
  {
    key: "market_view",
    value: "Market View",
  },
  {
    key: "allocation_optimizer",
    value: "Allocation Optimizer",
  },
];

export type ProductTypes = "modeling" | "forecasts" | "odinchat";

export const statusList: ItemType<IProfilePagePermissionType>[] = [
  {
    key: "active",
    value: "Active",
  },
  {
    key: "inactive",
    value: "Inactive",
  },
  {
    key: "hidden",
    value: "Hidden",
  },
];

export const statusRadioList = statusList.map(({ key, value }) => ({ value: key, label: value }));

export const featuresGlobalList = [
  // Macro Planner page
  { key: "modeling/multi-assets", value: "Macro-Planner/Multi-Assets", featureKey: "Multi-Assets" },
  {
    key: "modeling/equity-sectors",
    value: "Macro-Planner/Equity Sectors",
    featureKey: "Equity Sectors",
  },
  { key: "modeling/fixed-income", value: "Macro-Planner/Fixed Income", featureKey: "Fixed Income" },
  { key: "modeling/commodities", value: "Macro-Planner/Commodities", featureKey: "Commodities" },
  { key: "modeling/custom", value: "Macro-Planner/Custom", featureKey: "Custom" },

  // Market View page
  { key: "market-view/chart-1", value: "Market-View/Market Spider", featureKey: "chart-1" },
  { key: "market-view/chart-2", value: "Market-View/S&P 500", featureKey: "chart-2" },
  {
    key: "market-view/chart-3",
    value: "Market-View/(Put, Call) Ratio",
    featureKey: "chart-3",
  },
  { key: "market-view/chart-4", value: "Market-View/Performing", featureKey: "chart-4" },
  {
    key: "market-view/chart-5",
    value: "Market-View/Nominal Consumer Spending",
    featureKey: "chart-5",
  },
];
