import {
  createAsyncThunk,
  createSelector,
  createSlice,
  nanoid,
  PayloadAction,
} from "@reduxjs/toolkit";

import { paginator } from "@/constants";
import { PortfolioService } from "@/services";
import { IDuplicate, IPortfolio } from "@/types/portfolio";
import { notification } from "@/utils/notification";

import { RootState } from "../index";

interface IPortfolioDataState {
  list: IPortfolio[] | null;
  selected: IPortfolio | null;
  isLoading: boolean;
  isLoadingDuplicate: boolean;
  isLoadingSelected: boolean;
  page: number;
  perPage: number;
}

const initialState: IPortfolioDataState = {
  list: null,
  selected: null,
  isLoading: false,
  isLoadingSelected: false,
  isLoadingDuplicate: false,
  page: paginator.page,
  perPage: paginator.default,
};

export const getTemplatePortfolioList = createAsyncThunk("portfolioTemplate/list", async () => {
  const results = await PortfolioService.getTemplatePortfolioList();
  return results.data.result;
});

// export const copyTemplatePortfolio = createAsyncThunk(
//   "portfolioTemplate/copy",
//   async ({ id, data }: { id: number; data: IDuplicate }) => {
//     data.name = `${data.name}-copy-${nanoid(12)}`;
//     const result = await PortfolioService.copyTemplatePortfolio(id);
//     return result.data.result;
//   }
// );

export const templatePortfolioSlice = createSlice({
  name: "templatePortfolio",
  initialState,
  reducers: {
    setSelected: (state, action: PayloadAction<IPortfolio>) => {
      state.selected = action.payload;
    },
    resetSelected: (state) => {
      state.selected = null;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setPerPage: (state, { payload }: PayloadAction<number>) => {
      state.perPage = payload;
    },
  },
  extraReducers: (builder) => {
    // Get Template Portfolios list
    builder
      .addCase(getTemplatePortfolioList.fulfilled, (state, action) => {
        state.list = action.payload;
        state.isLoading = false;
      })
      .addCase(getTemplatePortfolioList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTemplatePortfolioList.rejected, (state, action) => {
        state.isLoading = false;
        if (action.error.message) {
          notification.error(action.error.message);
        }
      });

    // Copy Template Portfolio portfolio
    // builder
    //   .addCase(copyTemplatePortfolio.fulfilled, (state, { payload }) => {
    //     if (state.list) {
    //       state.list.push({
    //         ...payload,
    //         permissions: {
    //           has_read: true,
    //           has_delete: true,
    //           has_write: true,
    //         },
    //       });
    //     }
    //     if (payload && state.list === null) state.list = [payload];

    //     notification.success("Portfolio successful copied.");
    //     state.isLoadingDuplicate = false;
    //   })
    //   .addCase(copyTemplatePortfolio.pending, (state) => {
    //     state.isLoadingDuplicate = true;
    //   })
    //   .addCase(copyTemplatePortfolio.rejected, (state, action) => {
    //     state.isLoadingDuplicate = false;
    //     if (action.error.message) {
    //       notification.error(action.error.message);
    //     }
    //   });
  },
});

export const { setSelected, resetSelected, setPage, setPerPage } = templatePortfolioSlice.actions;

const state = (state: RootState) => state;

export const selectTemplatePortfolio = createSelector(state, (state) => state.templatePortfolio);

export default templatePortfolioSlice.reducer;
