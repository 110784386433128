import { AxiosResponse } from "axios";

import axios from "@/services/axios.config";
import { ICompareResponseData, IPremission } from "@/types";
import {
  IAllocationFormData,
  IBenchmark,
  IDuplicate,
  IDuplicateResponse,
  IMarkAsDefaultTemplatePlayload,
  IPortfolio,
  IPortfolioListResponse,
  IPortfolioPayload,
  IPortfolioResponse,
  IPortfolioStrategies,
} from "@/types/portfolio";

export const PortfolioService = {
  abort: new AbortController(),
  savePortfolio: (data: IPortfolioPayload): Promise<AxiosResponse<IPortfolio>> =>
    axios.post("/portfolios", data),
  getPortfolioList: (): Promise<AxiosResponse<IPortfolioListResponse>> => axios.get("/portfolios"),
  getTemplatePortfolioList: (): Promise<AxiosResponse<IPortfolioListResponse>> =>
    axios.get("/portfolios/predefined-templates"),
  copyTemplatePortfolio: (id: number): Promise<AxiosResponse<IPortfolio>> =>
    axios.post(`/portfolios/predefined-templates/${id}/clone `),

  getPortfolio: (id: number): Promise<AxiosResponse<IPortfolioResponse>> =>
    axios.get(`/portfolios/${id}`),
  updatePortfolio: (
    id: number,
    data: IPortfolioPayload
  ): Promise<AxiosResponse<IPortfolioResponse>> => axios.put(`/portfolios/${id}`, data),
  deletePortfolio: (id: number): Promise<AxiosResponse<IPortfolio>> =>
    axios.delete(`/portfolios/${id}`),
  duplicatePortfolio: (id: number, data: IDuplicate): Promise<AxiosResponse<IDuplicateResponse>> =>
    axios.post(`/portfolios/${id}/duplicate`, data),
  setPortfolioPermissions: async (
    id: number,
    permissions: IPremission[]
  ): Promise<AxiosResponse<{ result: IPremission[] }>> =>
    axios.post(`/portfolios/${id}/permissions`, { permissions }),
  getPortfolioPermissions: async (id: number): Promise<AxiosResponse<{ result: IPremission[] }>> =>
    axios.get(`/portfolios/${id}/permissions`),
  markAsDefaultTemplate: async (
    id: number,
    data: IMarkAsDefaultTemplatePlayload
  ): Promise<AxiosResponse<any>> => axios.put(`/portfolios/${id}/set-default-flag`, data),
  getBenchmarks: async (): Promise<AxiosResponse<{ result: IBenchmark[] }>> =>
    axios.get("/portfolios/benchmarks"),
  getStrategyList: async (): Promise<AxiosResponse<{ result: IPortfolioStrategies }>> =>
    axios.get("portfolios/strategies-list"),
  changeFavoriteStatus: (config: {
    value: boolean;
    id: number;
  }): Promise<AxiosResponse<{ result: IPortfolioResponse }>> =>
    axios.put(`/portfolios/${config.id}`, {
      show_on_home: config.value,
    }),

  // calculate allocations
  getAllocationsData(
    data: IAllocationFormData
  ): Promise<AxiosResponse<{ result: ICompareResponseData }>> {
    this.abort = new AbortController();
    return axios.post(`/portfolios/optimize-allocation`, data, { signal: this.abort.signal });
  },
};
