import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import {
  BlockedContent,
  ContentLayout,
  FooterLayout,
  InfoHeading,
  NoAccess,
  SubTitle,
  Tab,
} from "@/components";
import { faqDescriptionList } from "@/constants/faqDescription";
import { PortfolioMain, StrategiesDashboard } from "@/features";
import { useAppSelector, usePageInfo } from "@/hooks";
import { pagesStatusState } from "@/store/pagesPermission";

import { AllocationPermissions, ComparePermissions } from "./ModelingPermissions";

import styles from "./Modeling.module.scss";

const enum TabsEnum {
  MULTI_STRATEGY = "Multi Strategy",
  SINGLE_STRATEGY = "Single Strategy",
  COMPARE = "Compare",
  ALLOCATION_OPTIMIZER = "Allocation Optimizer",
}

const tabMapper: Record<string, { key: string; faq: keyof typeof faqDescriptionList }> = {
  "Multi Strategy": { key: "multi-strategy", faq: "portfolioMultiStrategy" },
  "Single Strategy": { key: "single-strategy", faq: "portfolioSingleStrategy" },
  "Allocation Optimizer": { key: "#", faq: "portfolioAllocationOptimizer" },
  Compare: { key: "#", faq: "portfolioAllocationOptimizer" },
};

const getCurrentTab = (currentTab: string | null) => {
  return currentTab ? decodeURIComponent(currentTab) : TabsEnum.SINGLE_STRATEGY;
};

export const Modeling = () => {
  const [search, setSearch] = useSearchParams();
  const currentTab = getCurrentTab(search.get("current_tab"));
  const [activeTab, setActiveTab] = useState<string>(currentTab);
  const { config } = useAppSelector(pagesStatusState);

  const { textInfo, video } = usePageInfo(tabMapper[activeTab].key);

  const selectTabHandle = (tabName: string) => {
    setActiveTab(tabName);
    setSearch({ current_tab: encodeURIComponent(tabName) });
  };

  const tabsList = useMemo(() => {
    const allocationsTab = {
      key: TabsEnum.ALLOCATION_OPTIMIZER,
      title: TabsEnum.ALLOCATION_OPTIMIZER,
      component:
        config && config["allocation_optimizer"] === "inactive" ? (
          <BlockedContent />
        ) : (
          <AllocationPermissions />
        ),
    };

    const tabItems = [
      {
        key: TabsEnum.SINGLE_STRATEGY,
        title: TabsEnum.SINGLE_STRATEGY,
        component: <StrategiesDashboard />,
      },
      {
        key: TabsEnum.MULTI_STRATEGY,
        title: TabsEnum.MULTI_STRATEGY,
        component: <PortfolioMain />,
      },
      {
        key: TabsEnum.COMPARE,
        title: TabsEnum.COMPARE,
        component: <ComparePermissions />,
      },
    ];

    if ((config && config["allocation_optimizer"] !== "hidden") || config === null) {
      tabItems.splice(2, 0, allocationsTab);
    }

    return tabItems;
  }, [config]);

  return (
    <FooterLayout showLiveMarketSlider>
      <ContentLayout>
        <div className={styles.container}>
          <InfoHeading
            helpInfo={textInfo}
            tutorialLink={video}
            faqList={faqDescriptionList[tabMapper[activeTab].faq]}
          >
            Portfolio Builder - <SubTitle text={activeTab} />
          </InfoHeading>
          <Tab tabs={tabsList} selected={activeTab} onChange={selectTabHandle} />
        </div>
      </ContentLayout>
    </FooterLayout>
  );
};
