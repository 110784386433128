import { AxiosResponse } from "axios";

import {
  IUserGlobalAccessConfig,
  IUserPagesAccessControl,
  IUserPagesPermissionData,
} from "@/types";

import axios from "./axios.config";

export const mocDataResponse = {
  // market_view: "hidden",
  // etf_intelligence: "inactive",
  // events_calendar: "inactive",
  // home: "inactive",
  // data: "inactive",
  // portfolio_builder_single_strategy: "active",
  // portfolio_builder_multi_strategy: "active",
  // portfolio_builder_compare: "active",
  // portfolio_builder_allocation_optimizer: "active",
  // forecast_companies: "active",
  // forecast_industries: "active",
  // forecast_sectors: "active",
  // forecast_macro: "active",
  // macro_planner_multi_asset: "active",
  // macro_planner_fixed_income: "active",
  // macro_planner_equity_sectors: "active",
  // macro_planner_commodities: "active",
  // macro_planner_custom: "active",
  forecast: "active",
  modeling: "active",
  odin_chat: "active",
};

export type PagesStatusResponseType = typeof mocDataResponse;

export const pagesPermissionService = {
  getPagesStatus: async (email: string): Promise<AxiosResponse<IUserPagesAccessControl | object>> =>
    axios.get(`/users/access-control?email=${encodeURIComponent(email)}`),
  updatePagesStatus: async (
    email: string,
    data: IUserPagesPermissionData
  ): Promise<AxiosResponse<{ message: string }>> =>
    axios.put(`/users/access-control?email=${encodeURIComponent(email)}`, data),
  getUserPagesAccessStatus: async (): Promise<AxiosResponse<IUserPagesAccessControl | object>> =>
    axios.get(`/profile/access-control`),

  getUserGlobalAccessStatus: async (
    email: string
  ): Promise<AxiosResponse<{ result: IUserGlobalAccessConfig }>> => {
    const prefix = `?email=${encodeURIComponent(email)}`;
    return axios.get(`/users/global-permissions${prefix}`);
  },

  getProfileGlobalAccessStatus: async (): Promise<
    AxiosResponse<{ result: IUserGlobalAccessConfig }>
  > => {
    return axios.get(`/profile/global-permissions`);
  },

  updateGlobalAccessStatus: async (
    data: IUserGlobalAccessConfig,
    email: string
  ): Promise<AxiosResponse<{ message: string }>> =>
    axios.put(`/users/global-permissions?email=${encodeURIComponent(email)}`, data),
};
