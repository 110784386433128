import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";

import { IPortfolioStrategies } from "@/types";
import { notification } from "@/utils/notification";

import { PortfolioService } from "../../services/potrfolioService";
import { RootState } from "../index";

interface ITikersState {
  isLoading: boolean;
  results: IPortfolioStrategies | null;
}

const initialState: ITikersState = {
  results: null,
  isLoading: false,
};

export const fetchCustomeStrategyList = createAsyncThunk("customeStrategiesList/list", async () => {
  const res = await PortfolioService.getStrategyList();
  return res?.data.result;
});

export const customeStrategyListSlice = createSlice({
  name: "customeStrategiesList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomeStrategyList.fulfilled, (state, action) => {
        state.results = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchCustomeStrategyList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCustomeStrategyList.rejected, (state, action) => {
        state.isLoading = false;
        if (action.error.message) {
          notification.error(action.error.message);
        }
      });
  },
});

const state = (state: RootState) => state;

export const customeStrategyListState = createSelector(state, (state) => state.customStrategies);

export default customeStrategyListSlice.reducer;
