import { Fragment } from "react";

import { VirtualList } from "@/components/VirtualList/VirtualList";
import { keyMapper } from "@/utils/compare";

import { useDetectEmptySpace } from "./hooks/useDetectEmptySpace";
import { useDropdownSearch } from "./hooks/useDropdownSearch";
import { Loader } from "../Loader/Loader";
import { TooltipWrapper } from "../Tooltip/Tooltip";

import styles from "./DropdownSearch.module.scss";

import { ReactComponent as ClearIcon } from "@images/clear.svg";
import { ReactComponent as SecrhIcon } from "@images/search.svg";

const DEFAULT_VISIBLE_ROWS = 7;
const DEFAULT_ITEM_HEIGHT = 36;
const CUSTOM_GROUP_KEY_NAME = "_group_";

export type ItemType = { key: string; value: string; id?: number; info?: string; title?: string };

interface IDropdownProps<T> {
  list: ItemType[];
  onSelect: (name: T, item: ItemType) => void;
  actionElement?: () => JSX.Element | null;
  selected: ItemType | null;
  label?: string;
  name: T;
  disable?: boolean;
  placeholder?: string;
  loading?: boolean;
  isRequired?: boolean;
  isClearable?: boolean;
  isUpperCase?: boolean;
  visibleRows?: number;
  isReadonly?: boolean;
}

export function DropdownSearch<T extends string>({
  list,
  onSelect,
  selected,
  label,
  name,
  disable,
  placeholder,
  loading = false,
  isRequired,
  isClearable,
  actionElement,
  isUpperCase,
  visibleRows = 5,
  isReadonly = false,
}: IDropdownProps<T>) {
  const {
    itemRef,
    inputRef,
    toggle,
    value,
    selectHandler,
    changeHandler,
    focusHandler,
    filteredList,
    onClear,
  } = useDropdownSearch(
    list,
    selected,
    (item: ItemType) => {
      onSelect(name, item);
    },
    loading,
    isUpperCase
  );

  const { listRef, isListOverflow } = useDetectEmptySpace({
    count: filteredList.length,
    visibleRows: DEFAULT_VISIBLE_ROWS,
    itemHeight: DEFAULT_ITEM_HEIGHT,
  });

  const ListItem = (props: { item: ItemType }) => {
    const { item } = props;
    const selectItemHandler = () => {
      selectHandler(item);
    };

    return (
      <li
        style={{ height: DEFAULT_ITEM_HEIGHT }}
        className={`${styles.listItem} ${selected?.value === item.value ? styles.active : ""} ${
          item.key === CUSTOM_GROUP_KEY_NAME ? styles.groupItem : ""
        }`}
        onClick={selectItemHandler}
        data-label={keyMapper.getInfo(item.key)}
      >
        <span className={styles.info}>{item.value}</span>
      </li>
    );
  };

  const maxHeight = visibleRows * DEFAULT_ITEM_HEIGHT + 16;
  const hasLabel = label;
  const isListEmpty = filteredList.length === 0;
  const hasCustomLabel = !!actionElement;

  return (
    <div
      className={`${styles.container} ${disable ? styles.disabled : ""} ${
        toggle ? styles.open : ""
      } ${loading ? styles.loading : ""} ${hasCustomLabel ? styles.customLabel : ""}`}
      data-readonly={isReadonly}
    >
      <div className={styles.innerWrapper} ref={itemRef}>
        <div className={styles.labelWrapper}>
          <div className={styles.labelInner}>
            {!!label && (
              <span className={`${styles.label} ${isRequired ? styles.required : ""}`}>
                {label}
              </span>
            )}
            {actionElement ? actionElement() : null}
          </div>

          <div className={styles.inputContainer} ref={listRef}>
            <div className={styles.iconContainer}>
              <SecrhIcon />
            </div>
            {isClearable && value && (
              <div className={`${styles.iconContainer} ${styles.clear}`} onClick={onClear}>
                <ClearIcon />
              </div>
            )}
            {loading ? (
              <div className={styles.loaderContainer}>
                <Loader />
              </div>
            ) : (
              <input
                className={styles.input}
                value={value}
                name={name}
                id={name}
                onFocus={focusHandler}
                onChange={changeHandler}
                ref={inputRef}
                placeholder={placeholder}
                autoComplete="off"
              />
            )}
          </div>
        </div>

        <div
          className={`${styles.listContainer} ${isListOverflow ? styles.reversePosition : ""} ${
            hasLabel ? "" : styles.withoutLabel
          }`}
          style={{ maxHeight }}
        >
          <div className={styles.wrapper} style={{ maxHeight }}>
            <ul className={styles.list}>
              {isListEmpty ? (
                <li
                  style={{ height: DEFAULT_ITEM_HEIGHT }}
                  className={`${styles.listItem} ${styles.empty}`}
                >
                  List is Empty
                </li>
              ) : (
                <VirtualList visibleRows={visibleRows} itemHeight={DEFAULT_ITEM_HEIGHT}>
                  {filteredList.map((item, idx) => (
                    <Fragment key={`${item.key + idx}-${item.info ? "info" : "default"}`}>
                      {item.info ? (
                        <TooltipWrapper
                          text={item.info}
                          show={toggle}
                          delay={700}
                          title={item.title}
                        >
                          <ListItem item={item} />
                        </TooltipWrapper>
                      ) : (
                        <ListItem item={item} />
                      )}
                    </Fragment>
                  ))}
                </VirtualList>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
