import { ChangeEvent, FC, useEffect, useState } from "react";

import { InputItem } from "@/components";
import { useAppDispatch } from "@/hooks";
import { resetList, searchCommonStrategies } from "@/store/strategies/commonStrategies";
import { debounce } from "@/utils";

import styles from "./Search.module.scss";

interface IProps {
  hasSearch: (e: boolean) => void;
}

export const Search: FC<IProps> = ({ hasSearch }) => {
  const [inputKeywords, setInputKeywords] = useState<string>();
  const dispatch = useAppDispatch();

  const handleSearch = debounce((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInputKeywords(value);
    if (value.length) {
      dispatch(searchCommonStrategies(value));
    } else {
      dispatch(resetList());
      // dispatch(setPageNumber(1));
    }
  }, 1000);

  useEffect(() => {
    hasSearch(!!inputKeywords);
  }, [hasSearch, inputKeywords]);

  return (
    <div className={styles.searchWrapper}>
      <InputItem
        onChange={handleSearch}
        label=""
        name=""
        type="search"
        placeholder="Search by strategy key, description or type."
        noPaddingBottom
      />
    </div>
  );
};

export const EmptySearch = () => {
  return (
    <div className={styles.emptyContainer}>
      <p className={styles.info}>No strategy items were found based on your search.</p>
    </div>
  );
};
