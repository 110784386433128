import { useCallback, useEffect, useMemo } from "react";

import { paginator } from "@/constants";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { profileInfo } from "@/store/profile";
import { fetchCopyTemplate } from "@/store/strategies/my_strategies";
import {
  fetchTemplateStrategiesData,
  selectTemplateStrategies,
  setPage,
  setPerPage,
} from "@/store/strategies/template_strategies";
import { IStartegyCard } from "@/types";
import { prepareStrategiesList } from "@/utils";

export const useTemplateStrategies = () => {
  const dispatch = useAppDispatch();
  const { list, isLoading, page, perPage } = useAppSelector(selectTemplateStrategies);
  const { profile } = useAppSelector(profileInfo);

  const strategiesList = useMemo(() => {
    return list ? prepareStrategiesList(list) : [];
  }, [list, profile]);

  const selectPageHandler = useCallback((page: number) => {
    dispatch(setPage(page));
  }, []);

  const selectPerPageHandler = useCallback((perPage: number) => {
    dispatch(setPage(1));
    dispatch(setPerPage(perPage));
  }, []);

  const copyStrategy = useCallback((item: IStartegyCard) => {
    dispatch(fetchCopyTemplate(item.id));
  }, []);

  const strategiesPaginator = useMemo(() => {
    return {
      page,
      perPage,
      setPage: selectPageHandler,
      setPerPage: selectPerPageHandler,
      perPageList: paginator.perPageList,
    };
  }, [selectPageHandler, selectPerPageHandler, page, perPage]);

  useEffect(() => {
    if (!list) {
      dispatch(fetchTemplateStrategiesData());
    }
  }, [list]);

  return {
    strategiesPaginator,
    strategiesList,
    isLoading,

    copyStrategy,
  };
};
