import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";

import { notification } from "@/utils/notification";

import { RootState } from "./index";
import { TikersService } from "../services/tikerServices";

interface ITikersState {
  isLoading: boolean;
  isLoadingSp500: boolean;
  requested: boolean;
  results: string[];
  resultsEtfs: string[];
  resultSp500: string[];
}

const initialState: ITikersState = {
  results: [],
  resultsEtfs: [],
  resultSp500: [],
  isLoading: false,
  isLoadingSp500: false,
  requested: false,
};

export const fetchTikersList = createAsyncThunk("tikers/list", async () => {
  const res = await TikersService.fetchTikersList();
  return res?.data.result;
});

export const fetchTikersEtfsList = createAsyncThunk("tikers/etfs-list", async () => {
  const res = await TikersService.fetchTikersEtfsList();
  return res?.data.result;
});

export const fetchTikersSp500List = createAsyncThunk("tikers/sp500-list", async () => {
  const res = await TikersService.fetchTikersSp500List();
  return res?.data.result;
});

export const tikersSlice = createSlice({
  name: "tikersList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTikersList.fulfilled, (state, action) => {
        state.requested = true;
        state.results = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchTikersList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTikersList.rejected, (state, action) => {
        state.isLoading = false;
        if (action.error.message) {
          notification.error(action.error.message);
        }
      })
      .addCase(fetchTikersEtfsList.fulfilled, (state, action) => {
        state.requested = true;
        state.resultsEtfs = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchTikersEtfsList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTikersEtfsList.rejected, (state, action) => {
        state.isLoading = false;
        if (action.error.message) {
          notification.error(action.error.message);
        }
      })
      .addCase(fetchTikersSp500List.fulfilled, (state, action) => {
        state.resultSp500 = action.payload;
        state.isLoadingSp500 = false;
      })
      .addCase(fetchTikersSp500List.pending, (state) => {
        state.isLoadingSp500 = true;
      })
      .addCase(fetchTikersSp500List.rejected, (state, action) => {
        state.isLoadingSp500 = false;
        if (action.error.message) {
          notification.error(action.error.message);
        }
      });
  },
});

const state = (state: RootState) => state;

export const tikersState = createSelector(state, (state) => state.tickers);

export default tikersSlice.reducer;
