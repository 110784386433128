import { forwardRef, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";

import { ModalLayout, ActionsMenu, IMenuItem } from "@/components";
import { Confirm } from "@/components/Dialogs/Confirm";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { ReactComponent as CopyIcon } from "@/images/copy.svg";
import { ReactComponent as DeleteIcon } from "@/images/nest.svg";
import { ReactComponent as PortfolioIcon } from "@/images/portfolio_square.svg";
import {
  changeFavoriteStatus,
  deletePortfolio,
  duplicatePortfolio,
  fetchChangeFavoriteStatus,
  fetchCopyPortfolioTemplate,
  resetCopiedTemplateId,
} from "@/store/portfolios/portfolio";
import { profileInfo } from "@/store/profile";
import { IDuplicate, IPortfolio } from "@/types";
import { getFormattedDate, searchStringInText } from "@/utils";

import styles from "./PortfolioItem.module.scss";

interface IProps {
  portfolio: IPortfolio;
  hasMenu?: boolean;
  isMyPortfolio?: boolean;
  isFavorites?: boolean;
  isFavProgress?: boolean;
  idx?: number;
  searchText?: string;
  isTemplate?: boolean;
  link: string;
  isCopiedItem?: boolean;
}

export const MENU_ITEMS: IMenuItem[] = [
  {
    icon: <CopyIcon />,
    type: "copy",
    label: "Copy",
    key: "copy",
  },
  {
    icon: <DeleteIcon />,
    type: "delete",
    label: "Delete",
    key: "delete",
  },
];

export const PortfolioItem = forwardRef<HTMLDivElement, IProps>(
  ({ portfolio, hasMenu, idx, isFavorites, searchText, isTemplate, link, isCopiedItem }, ref) => {
    const { email } = portfolio.user ?? {};
    const { show_on_home, id, is_template, name, permissions, created_at } = portfolio;
    const [showModal, setShowModal] = useState(false);
    const itemRef = useRef<HTMLDivElement | null>(null);
    const { profile } = useAppSelector(profileInfo);

    const dispatch = useAppDispatch();
    const handleSelect = (menuItem: IMenuItem) => {
      const { key } = menuItem;
      if (!id) return;
      if (key === "delete") {
        setShowModal(true);
      }
      if (key === "copy" && !isTemplate) {
        const data: IDuplicate = {
          is_template: false,
          name,
        };
        dispatch(duplicatePortfolio({ id, data }));
      }
      if (key === "copy" && isTemplate) {
        dispatch(fetchCopyPortfolioTemplate(id));
      }
    };

    const handleConfirmDelete = () => {
      if (!id) return;
      dispatch(deletePortfolio(id));
      setShowModal(false);
    };
    const handleCancel = () => {
      setShowModal(false);
    };

    const animationEndHandler = (ev: React.AnimationEvent<HTMLDivElement>) => {
      if (isFavorites && show_on_home && ev.target === itemRef.current && id !== undefined) {
        dispatch(changeFavoriteStatus(id));
        dispatch(fetchChangeFavoriteStatus({ value: !show_on_home, id }));
      }
    };

    const menu = MENU_ITEMS.filter(
      ({ type }) =>
        !(
          (isTemplate && type === "delete") ||
          (isFavorites && type === "copy") ||
          ((!permissions?.has_delete || isFavorites) && type === "delete") ||
          (!permissions?.has_write && type === "edit")
        )
    );

    const isActionMenuDisabled = menu.length === 0;

    useEffect(() => {
      setTimeout(() => {
        if (itemRef.current) itemRef.current.classList.add(styles.onStartAnimated);
      }, 50 + (idx || 0) * 35);
    }, [idx]);

    const groupName = (name: string) => {
      const result = searchStringInText(name, searchText);

      return result.length ? (
        result.map((item, idx) => (
          <span data-match={searchText?.toLowerCase() === item.toLowerCase()} key={idx}>
            {item}
          </span>
        ))
      ) : (
        <span>{name}</span>
      );
    };

    const transitionEndHandler = () => {
      if (isCopiedItem && itemRef.current) {
        dispatch(resetCopiedTemplateId());
      }
    };

    useEffect(() => {
      if (isCopiedItem && itemRef.current) {
        itemRef.current.scrollIntoView({ block: "center", behavior: "smooth" });
        itemRef.current.classList.add(styles.copied);
      }
    }, [isCopiedItem]);

    return (
      <div
        className={styles.container}
        ref={itemRef}
        onAnimationEnd={animationEndHandler}
        data-template={isTemplate}
        onTransitionEnd={transitionEndHandler}
      >
        <div ref={ref} className={styles.inner}>
          {is_template && <span className={styles.template}>Template</span>}
          <NavLink to={link}>
            <div className={`${styles.innerContainer}`}>
              <div className={styles.portfolioIcon}>
                <PortfolioIcon />
                {isTemplate ? <span className={styles.label}>Template</span> : null}
              </div>
              <div className={styles.info}>
                <p className={styles.title}>{groupName(name)}</p>
                <span className={styles.index}>
                  ID: <span>{id}</span>
                </span>
                <span className={styles.label}>Portfolio</span>
              </div>
            </div>
            {!isTemplate ? (
              <div className={styles.innerContainerHovered}>
                <div className={styles.hoveredDragIcon}>
                  <PortfolioIcon />
                </div>
                <div className={styles.infoHovered}>
                  <p>{groupName(name)}</p>
                  <span>{email}</span>
                  <span>{getFormattedDate(created_at)}</span>
                </div>
              </div>
            ) : null}
          </NavLink>
        </div>
        {hasMenu && (
          <ActionsMenu menuItems={menu} onSelect={handleSelect} disabled={isActionMenuDisabled} />
        )}
        <ModalLayout show={showModal} onClose={handleCancel}>
          <Confirm onConfirm={handleConfirmDelete} onCancel={handleCancel} />
        </ModalLayout>
      </div>
    );
  }
);
