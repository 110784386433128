import { AxiosResponse } from "axios";

import {
  IForcastChart,
  IForecastMacroItem,
  IEHTable,
  IForecastEquities,
  ForecastQueryType,
  IForecastExtraChart,
  IExtraEHTable,
  IInvoledTikers,
  DataType,
  IEquitiesRevisionItem,
} from "@/types/forecast";

import axios from "./axios.config";

export const getForecastEquitiesLink = (ticker: string, isOdincast?: boolean) =>
  `/forecast/equities-graphs?ticker=${ticker}${isOdincast ? "&origin=odincast" : ""}`;

export const ForecastService = {
  getMacroBubbles: (): Promise<AxiosResponse<{ result: IForecastMacroItem[] }>> =>
    axios.get("/forecast/macro-bubbles"),
  getMacroTracker: (ticker: string): Promise<AxiosResponse<{ result: IEHTable }>> =>
    axios.get(`/forecast/macro-tracker?indicator=${ticker}`),
  getMacroChart: (ticker: string): Promise<AxiosResponse<{ result: IForcastChart }>> =>
    axios.get(`/forecast/macro-chart?indicator=${ticker}`),
  getEquities: (
    query: ForecastQueryType
  ): Promise<AxiosResponse<{ result: IForecastEquities[]; _meta: { hidden_columns: string[] } }>> =>
    axios.get(`/forecast/${query}`),
  getEquitiesChart: (
    ticker: string,
    isOdincast?: boolean
  ): Promise<AxiosResponse<{ result: any }>> =>
    axios.get(getForecastEquitiesLink(ticker, isOdincast)),

  getEquitiesRevision: (
    ticker: string
  ): Promise<AxiosResponse<{ result: Record<string, IEquitiesRevisionItem[]> }>> =>
    axios.get(`/forecast/equities-revisions?ticker=${ticker}`),

  getIndustryChart: (
    industry: string,
    isOdincast?: boolean
  ): Promise<AxiosResponse<{ result: any }>> =>
    axios.get(
      `/forecast/industries-graphs?industry=${industry}${isOdincast ? "&origin=odincast" : ""}`
    ),
  getSectorChart: (sector: string, isOdincast?: boolean): Promise<AxiosResponse<{ result: any }>> =>
    axios.get(`/forecast/sectors-graphs?sector=${sector}${isOdincast ? "&origin=odincast" : ""}`),
  getMacroExtraChart: (ticker: string): Promise<AxiosResponse<{ result: IForecastExtraChart }>> =>
    axios.get(`/forecast/macro-chart?indicator=${ticker}`),
  getMacroExtraTracker: (ticker: string): Promise<AxiosResponse<{ result: IExtraEHTable }>> =>
    axios.get(`/forecast/macro-tracker?indicator=${ticker}`),
  getInvolvedTickersSectors: (
    ticker: string
  ): Promise<AxiosResponse<{ result: IInvoledTikers[] }>> =>
    axios.get(`/forecast/involved-tickers-sectors?sector=${ticker}`),
  getInvolvedTickersIndustries: (
    ticker: string
  ): Promise<AxiosResponse<{ result: IInvoledTikers[] }>> =>
    axios.get(`/forecast/involved-tickers-industries?industry=${ticker}`),
  getPriceActions: (ticker: string): Promise<AxiosResponse<{ result: DataType }>> =>
    axios.get("forecast/price-change-graph", {
      params: {
        ticker,
        origin: "odincast",
      },
    }),
  getProductSegments: (ticker: string): Promise<AxiosResponse<{ result: DataType }>> =>
    axios.get(`forecast/product-segments?ticker=${ticker}&origin=odincast`),
  getOperatingMetrics: (ticker: string): Promise<AxiosResponse<{ result: DataType }>> =>
    axios.get(`forecast/operating-metrics?ticker=${ticker}&origin=odincast`),
  getDescription: (ticker: string): Promise<AxiosResponse<{ result: { description: string } }>> =>
    axios.get(`forecast/ticker-description?ticker=${ticker}`),
  getChartCSV: (path: string): Promise<AxiosResponse<Record<string, string | number | null>[]>> =>
    axios.get(`/forecast${path}`),
};
