import { useCallback, useEffect, useMemo } from "react";

import { paginator } from "@/constants";
import { useAppDispatch, useAppSelector } from "@/hooks";
import {
  getTemplatePortfolioList,
  selectTemplatePortfolio,
  setPage,
  setPerPage,
} from "@/store/portfolios/templatePortfolios";

export const useTemplatePortfolios = () => {
  const { list, isLoading, page, perPage } = useAppSelector(selectTemplatePortfolio);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (list) return;
    dispatch(getTemplatePortfolioList());
  }, [dispatch, list]);

  const selectPageHandler = useCallback((page: number) => {
    dispatch(setPage(page));
  }, []);

  const selectPerPageHandler = useCallback((perPage: number) => {
    dispatch(setPage(1));
    dispatch(setPerPage(perPage));
  }, []);

  const portfoliosPaginator = useMemo(() => {
    return {
      page,
      perPage,
      setPage: selectPageHandler,
      setPerPage: selectPerPageHandler,
      perPageList: paginator.perPageList,
    };
  }, [selectPageHandler, selectPerPageHandler, page, perPage]);

  return {
    portfoliosPaginator,
    portfolioList: list,
    isLoading,
  };
};
