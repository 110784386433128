import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { Heading, IMenuItem, InputItem, LoaderWrapper, Pagination } from "@/components";
// import { SharedStrategies } from "../SharedStrategies/SharedStrategies";
import { ROUTES } from "@/constants";
import { usePagination, useSearchTextInList } from "@/hooks";
import { IStartegyCard } from "@/types";

import { useTemplateStrategies } from "./hooks/useTemplateStrategies";
import { StrategiesList } from "../../StrategiesList/StrategiesList";

import styles from "./TemplateStrategies.module.scss";

interface IProps {
  name?: string;
}

export const TemplateStrategies: FC<IProps> = () => {
  const { strategiesPaginator, strategiesList, isLoading, copyStrategy } = useTemplateStrategies();
  const { page, perPage, setPage, setPerPage, perPageList } = strategiesPaginator;
  const navigate = useNavigate();

  const selectHandler = useCallback((item: IStartegyCard) => {
    navigate(
      `${ROUTES.strategiesBuilder.path}/template/${item.id}?name=${encodeURIComponent(item.name)}`
    );
  }, []);

  const selectMenuHandler = useCallback(
    (menuItem: IMenuItem, item: IStartegyCard) => {
      if (menuItem.type === "copy") {
        copyStrategy(item);
      }
    },
    [copyStrategy]
  );

  const {
    filteredList: searchFiltered,
    searchText,
    handleSearch,
    handleEnter,
  } = useSearchTextInList(strategiesList, setPage);

  const { totalPages, paginatedList } = usePagination<IStartegyCard>({
    list: searchFiltered,
    page,
    perPage,
  });

  return (
    <LoaderWrapper loading={isLoading} fullWidth>
      <div className={styles.container}>
        <div className={styles.headingGroup}>
          <Heading type="h3" className={styles.subHeading}>
            Template Strategies
          </Heading>
        </div>
        <div className={styles.searchWrapper}>
          <InputItem
            onChange={handleSearch}
            onEnter={handleEnter}
            label=""
            name="strategy-search"
            type="search"
            placeholder="Strategy name"
            noPaddingBottom
          />
        </div>
        <div className={`${styles.wrapper}`}>
          <div className={styles.paginationContainer}>
            <Pagination
              count={strategiesList?.length || 0}
              perPageList={perPageList}
              totalCount={totalPages}
              perPage={perPage}
              currentPage={page}
              onChangePerPage={setPerPage}
              onPage={setPage}
              countLabel="Strategies"
            />
          </div>
          <StrategiesList
            isFavorites={false}
            isTemplates={true}
            strategiesList={paginatedList}
            selectHandler={selectHandler}
            selectMenuHandler={selectMenuHandler}
            searchText={searchText}
          />
        </div>
      </div>
    </LoaderWrapper>
  );
};
