import { IProfilePagePermissionType, IUser } from "./user";

export interface IUserPermissions {
  has_write: boolean;
  has_delete: boolean;
  has_read: boolean;
}

export interface IPermissions {
  canRead: boolean;
  canDelete: boolean;
  canUpdate: boolean;
}

export interface IPremission extends IUserPermissions {
  user_id: string;
  user?: IUser | null;
}

export const enum PermisionsEnum {
  READ = "read",
  UPDATE = "update",
  DELETE = "delete",
}

export interface IUserPermissionTable {
  id: number | null;
  name: string;
  email: string;
  read: boolean;
  update: boolean;
  delete: boolean;
}

export interface IPermissionsData {
  id: number;
  data: {
    permissions: IPremission[];
  };
}

export type UserRoleType = "admin" | "superadmin" | "sales" | "regular";

export interface IRole {
  key: UserRoleType;
  value: string;
}

export type UserActions =
  | "default"
  | "invite"
  | "manage-user"
  | "manage-company"
  | "download"
  | "adminPanel";

export interface IUserPagesPermissionData {
  access_control: Record<string, IProfilePagePermissionType>;
}

export type UserPagesAccessConfig = Record<string, IProfilePagePermissionType>;

export interface IUserPagesAccessControl {
  id: number;
  email: string | null;
  config: UserPagesAccessConfig;
  created_at: string;
  updated_at: string;
}

export interface IResourcesAccess {
  tutorials?: Record<string, IProfilePagePermissionType>;
  overview?: Record<string, IProfilePagePermissionType>;
  solutions?: Record<string, IProfilePagePermissionType>;
}

export interface IUserGlobalAccessConfig {
  id?: number;
  config?: {
    features?: Record<string, IProfilePagePermissionType>;
    resources?: IResourcesAccess;
  };
}
