import { ISlidersConfig } from "@/types/warRoom";
import { uncheck } from "@/utils/warRoom";

export const sliders: ISlidersConfig = {
  changeRate: {
    label: "Fed Fund Rate Change​",
    name: "change_rate",
    rangeLabelMin: "-5%",
    rangeLabelMax: "+5%",
    min: -5,
    max: 5,
    step: 0.25,
    defaultValue: 0,
  },
  horizon: {
    label: "Horizon​",
    name: "horizon",
    rangeLabelMin: "1 Month",
    rangeLabelMax: "24 Months",
    min: 1,
    max: 24,
    step: 1,
    defaultValue: 6,
  },
  leverage: {
    label: "Max Portfolio Leverage",
    name: "portfolio_leverage",
    rangeLabelMin: "No leverage",
    rangeLabelMax: "100%",
    min: 0,
    max: 100,
    step: 5,
    defaultValue: 0,
  },
  beta: {
    label: "Max Portfolio Beta (vs. Benchmark)",
    name: "portfolio_beta",
    rangeLabelMin: "0",
    rangeLabelMax: "2",
    min: 0,
    max: 2,
    step: 0.1,
    defaultValue: 1,
  },
  interval: {
    label: "Allowed Allocation Range Per Asset",
    name: "interval",
    rangeLabelMin: "-100%",
    rangeLabelMax: "100%",
    min: -100,
    max: 100,
    step: 5,
    defaultValue: [0, 100],
  },
};

export const assetsCheckList = {
  bonds: {
    checked: true,
    label: "Bonds (since January 1926)",
    key: "Bonds",
  },
  russell: {
    checked: true,
    label: "Russell 2000 (since January 1926)",
    key: "Russell 2000",
  },
  "s&p500": {
    checked: true,
    label: "S&P 500 (since January 1928)",
    key: "S&P 500",
  },
  nasdaq: {
    checked: false,
    label: "NASDAQ 100 (since October 1985)",
    key: "Nasdaq 100",
  },
  commodities: {
    checked: true,
    label: "Commodities (since January 1970)",
    key: "Commodities",
  },
  dowjones: {
    checked: false,
    label: "Dow 30 (since January 1992)",
    key: "Dow 30",
  },
};

export const equityAssetsCheckList = {
  communicService: {
    checked: true,
    label: "Communication Services (XLC) (since June 2018)",
    key: "Communication Services (XLC)",
  },
  consumerDiscretionary: {
    checked: true,
    label: "Consumer Discretionary (XLY) (since December 1998)",
    key: "Consumer Discretionary (XLY)",
  },
  comsumerStaple: {
    checked: true,
    label: "Consumer Staple (XLP) (since December 1998)",
    key: "Consumer Staple (XLP)",
  },
  energy: {
    checked: true,
    label: "Energy (XLE) (since December 1998)",
    key: "Energy (XLE)",
  },
  financials: {
    checked: true,
    label: "Financials (XLF) (since December 1998)",
    key: "Financials (XLF)",
  },
  healthCare: {
    checked: true,
    label: "Health Care (XLV) (since December 1998)",
    key: "Health Care (XLV)",
  },
  Industrials: {
    checked: true,
    label: "Industrials (XLI) (since December 1998)",
    key: "Industrials (XLI)",
  },
  materials: {
    checked: true,
    label: "Materials (XLB) (since December 1998)",
    key: "Materials (XLB)",
  },
  realEstate: {
    checked: true,
    label: "Real Estate (XLRE) (since October 2015)",
    key: "Real Estate (XLRE)",
  },
  technology: {
    checked: true,
    label: "Technology (XLK) (since December 1998)",
    key: "Technology (XLK)",
  },
  utilities: {
    checked: true,
    label: "Utilities (XLU) (since December 1998)",
    key: "Utilities (XLU)",
  },
};

export const fixedIncomeAssetsList = {
  iShares: {
    checked: true,
    label: "iShares Core U.S. Aggregate Bond ETF (AGG) (since September 2003)",
    key: "iShares Core U.S. Aggregate Bond ETF (AGG)",
  },
  vanguardTotal: {
    checked: true,
    label: "Vanguard Total Bond Market ETF (BND) (since April 2007)",
    key: "Vanguard Total Bond Market ETF (BND)",
  },
  iSharedIBox: {
    checked: true,
    label: "iShares iBoxx $ Investment Grade Corporate Bond ETF (LQD) (since July 2002)",
    key: "iShares iBoxx $ Investment Grade Corporate Bond ETF (LQD)",
  },
  vanguardTotalInternational: {
    checked: true,
    label: "Vanguard Total International Bond ETF (BNDX) (since June 2013)",
    key: "Vanguard Total International Bond ETF (BNDX)",
  },
  spdrBloomberg: {
    checked: true,
    label: "SPDR Bloomberg Barclays 1-3 Month T-Bill ETF (BIL) (since May 2007)",
    key: "SPDR Bloomberg Barclays 1-3 Month T-Bill ETF  (BIL)",
  },
  vanguardIntermediate: {
    checked: true,
    label: "Vanguard Intermediate-Term Corporate Bond ETF (VCIT) (since November 2009)",
    key: "Vanguard Intermediate-Term Corporate Bond ETF (VCIT)",
  },
  iSghares20Year: {
    checked: true,
    label: "iShares 20+ Year Treasury Bond ETF (TLT) (since July 2002)",
    key: "iShares 20+ Year Treasury Bond ETF (TLT)",
  },
  iSgharesIBoxHighYeld: {
    checked: true,
    label: "iShares iBoxx $ High Yield Corporate Bond ETF (HYG) (since April 2007)",
    key: "iShares iBoxx $ High Yield Corporate Bond ETF (HYG)",
  },
  vanguardLongTerm: {
    checked: true,
    label: "Vanguard Long-Term Treasury ETF (VGLT) (since November 2009)",
    key: "Vanguard Long-Term Treasury ETF (VGLT)",
  },
  vanguardIntermediateTerm: {
    checked: true,
    label: "Vanguard Intermediate-Term Treasury ETF (VGIT) (since November 2009)",
    key: "Vanguard Intermediate-Term Treasury ETF (VGIT)",
  },
  vanguardShortTerm: {
    checked: true,
    label: "Vanguard Short-Term Treasury ETF (VGSH) (since November 2009)",
    key: "Vanguard Short-Term Treasury ETF (VGSH)",
  },
  vanguardTotalCorporate: {
    checked: true,
    label: "Vanguard Total Corporate Bond ETF (VTC) (since November 2017)",
    key: "Vanguard Total Corporate Bond ETF (VTC)",
  },
  vanguardTaxExempt: {
    checked: true,
    label: "Vanguard Tax-Exempt Bond ETF (VTEB) (since August 2015)",
    key: "Vanguard Tax-Exempt Bond ETF (VTEB)",
  },
  vanguardMortgageBacked: {
    checked: true,
    label: "Vanguard Mortgage-Backed Securities ETF (VMBS) (since November 2009)",
    key: "Vanguard Mortgage-Backed Securities ETF (VMBS)",
  },
  vanguardShortTermInflation: {
    checked: true,
    label: "Vanguard Short-Term Inflation Protected Securities ETF (VTIP) (since October 2012)",
    key: "Vanguard Short-Term Inflation Protected Securities ETF (VTIP)",
  },
  vanguardShortTermBond: {
    checked: true,
    label: "Vanguard Short-Term Bond ETF (BSV) (since April 2007)",
    key: "Vanguard Short-Term Bond ETF (BSV)",
  },
  janusHenderson: {
    checked: true,
    label: "Janus Henderson AAA CLO ETF (JAAA) (since October 2020)",
    key: "Janus Henderson AAA CLO ETF (JAAA)",
  },
};

export const commoditiesList = {
  NaturalGas: {
    checked: true,
    label: "Natural Gas (since October 2001)",
    key: "Natural Gas",
  },
  CrudeOil: {
    checked: true,
    label: "Crude Oil (since October 2001)",
    key: "Crude Oil",
  },
  Gasoline: {
    checked: true,
    label: "Gasoline (since October 2001)",
    key: "Gasoline",
  },
  JetFuel: {
    checked: true,
    label: "Jet Fuel (since October 2001)",
    key: "Jet Fuel",
  },
  Gold: {
    checked: true,
    label: "Gold (since October 2001)",
    key: "Gold",
  },
  Silver: {
    checked: true,
    label: "Silver (since October 2001)",
    key: "Silver",
  },
  Platinum: {
    checked: true,
    label: "Platinum (since October 2001)",
    key: "Platinum",
  },
  Copper: {
    checked: true,
    label: "Copper (since October 2001)",
    key: "Copper",
  },
  Lead: {
    checked: true,
    label: "Lead (since October 2001)",
    key: "Lead",
  },
  Aluminum: {
    checked: true,
    label: "Aluminum (since October 2001)",
    key: "Aluminum",
  },
  Iron: {
    checked: true,
    label: "Iron (since February 2013)",
    key: "Iron",
  },
  Coal: {
    checked: true,
    label: "Coal (since September 2013)",
    key: "Coal",
  },
  Corn: {
    checked: true,
    label: "Corn (since December 2004)",
    key: "Corn",
  },
  Wheat: {
    checked: true,
    label: "Wheat (since December 2004)",
    key: "Wheat",
  },
  Coffee: {
    checked: true,
    label: "Coffee (since October 2001)",
    key: "Coffee",
  },
  Sugar: {
    checked: true,
    label: "Sugar (since October 2001)",
    key: "Sugar",
  },
  CornOil: {
    checked: true,
    label: "Corn Oil (since December 2004)",
    key: "Corn Oil",
  },
  SoybeanOil: {
    checked: true,
    label: "Soybean Oil (since December 2004)",
    key: "Soybean Oil",
  },
  Chicken: {
    checked: true,
    label: "Chicken (since October 2001)",
    key: "Chicken",
  },
  Pork: {
    checked: true,
    label: "Pork (since October 2001)",
    key: "Pork",
  },
  Beef: {
    checked: true,
    label: "Beef (since October 2001)",
    key: "Beef",
  },
};

export const expectTypeList = {
  cpi: {
    key: "CPI",
    value: "cpi",
    label: "CPI Core",
    rangeLabel: "CPI YoY Change",
  },
  fedFundRate: {
    key: "Fed Rate",
    value: "fedFundRate",
    label: "Fed Fund Rate",
    rangeLabel: "Fed Fund Rate Change",
  },
  realGDP: {
    key: "Real GDP QoQ",
    value: "realGDP",
    label: "Real GDP",
    rangeLabel: "Annualized QoQ Real GDP Change",
  },
  treasuryYeld: {
    key: "Yield Curve",
    value: "treasuryYeld",
    label: "10-2 Year Treasury Yield Spread",
    rangeLabel: "10-2 Year Treasury Yield Spread Change",
  },
  nominalGDP: {
    key: "Nominal GDP QoQ",
    value: "nominalGDP",
    label: "Nominal GDP",
    rangeLabel: "Annualized QoQ Nominal GDP Change",
  },
  treasury10Year: {
    key: "10Yr Treasury",
    value: "treasury10Year",
    label: "10 Year Treasury",
    rangeLabel: "10 Year Treasury Change",
  },
  treasury2Year: {
    key: "2Yr Treasury",
    value: "treasury2Year",
    label: "2 Year Treasury",
    rangeLabel: "2 Year Treasury Change",
  },
};

export const economMetricConfig = {
  cpi: {
    rangeLabelMin: "-5%",
    rangeLabelMax: "+20%",
    min: -5,
    max: 20,
    step: 0.5,
    defaultValue: 0,
  },
  fedFundRate: {
    rangeLabelMin: "-5%",
    rangeLabelMax: "+5%",
    min: -5,
    max: 5,
    step: 0.25,
    defaultValue: 0,
  },
  realGDP: {
    rangeLabelMin: "-50%",
    rangeLabelMax: "+50%",
    min: -50,
    max: 50,
    step: 0.1,
    defaultValue: 2,
  },
  treasuryYeld: {
    rangeLabelMin: "-2%",
    rangeLabelMax: "+2%",
    min: -2,
    max: 2,
    step: 0.25,
    defaultValue: 0,
  },
  nominalGDP: {
    rangeLabelMin: "-50%",
    rangeLabelMax: "+50%",
    min: -50,
    max: 50,
    step: 0.1,
    defaultValue: 2,
  },
  treasury10Year: {
    rangeLabelMin: "-3%",
    rangeLabelMax: "+3%",
    min: -3,
    max: 3,
    step: 0.25,
    defaultValue: 0,
  },
  treasury2Year: {
    rangeLabelMin: "-3%",
    rangeLabelMax: "+3%",
    min: -3,
    max: 3,
    step: 0.25,
    defaultValue: 0,
  },
};

export const customGroupsList = [
  {
    key: "assetsCheckList",
    value: "Indices",
  },
  {
    key: "equityAssetsCheckList",
    value: "Equity Sectors",
  },
  {
    key: "commoditiesList",
    value: "Commodities",
  },
  {
    key: "fixedIncomeAssetsList",
    value: "Fixed Income",
  },
];

export const customDesiredAssets = {
  assetsCheckList: uncheck(assetsCheckList),
  commoditiesList: uncheck(commoditiesList),
  equityAssetsCheckList: uncheck(equityAssetsCheckList),
  fixedIncomeAssetsList: uncheck(fixedIncomeAssetsList),
};

export const enum MacroPlannerTabEnum {
  MULTI_ASSETS = "Multi-Assets",
  COMMODITIES = "Commodities",
  FIXED_INCOME = "Fixed Income",
  EQUITY_SECTORS = "Equity Sectors",
  CUSTOM = "Custom",
}

export type ExpectType = typeof expectTypeList;
