import {
  createSlice,
  createAsyncThunk,
  createSelector,
  PayloadAction,
  nanoid,
} from "@reduxjs/toolkit";

import { MyStrategyFilterType, myStrategyFilters, paginator } from "@/constants";
import { Errors } from "@/constants/errors";
import { strategiesService } from "@/services";
import { IStartegyCard, IStrategyData } from "@/types";
import { notification } from "@/utils/notification";

import { RootState } from "..";

interface IInitialState {
  isLoading: boolean;
  list: IStrategyData[] | null;
  page: number;
  perPage: number;
  filter: Record<MyStrategyFilterType, boolean>;
}

const initialState: IInitialState = {
  isLoading: false,
  list: null,
  page: paginator.page,
  perPage: paginator.default,
  filter: myStrategyFilters,
};

export const fetchTemplateStrategiesData = createAsyncThunk(
  "templateStrategies/fetchMyStrategiesData",
  async () => {
    const { data } = await strategiesService.getTemplateStrategies();
    return data.result;
  }
);

// export const fetchCopyTemplateStrategy = createAsyncThunk(
//   "templateStrategies/fetchCopyItem",
//   async (id: number) => {
//     // const copiedItem = { ...item, name: `${item.name}-copy-${nanoid(12)}` };
//     const { data } = await strategiesService.copyTemplateStrategy(id);
//     return { data };
//   }
// );

export const templateStrategiesSlice = createSlice({
  name: "templateStrategies",
  initialState,
  reducers: {
    initList: (state) => {
      state.list = null;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setPerPage: (state, { payload }: PayloadAction<number>) => {
      state.perPage = payload;
    },
    setFilter: (
      state,
      { payload }: PayloadAction<{ name: MyStrategyFilterType; checked: boolean }>
    ) => {
      const { name, checked } = payload;
      state.filter = { ...state.filter, [name]: checked };
    },
    resetFilter: (state) => {
      const result = Object.keys(state.filter).reduce((acc, key) => {
        acc[key as MyStrategyFilterType] = false;
        return acc;
      }, {} as Record<MyStrategyFilterType, boolean>);

      state.filter = result;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTemplateStrategiesData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTemplateStrategiesData.fulfilled, (state, { payload }) => {
        if (payload) state.list = payload;
        state.isLoading = false;
      })
      .addCase(fetchTemplateStrategiesData.rejected, (state) => {
        state.isLoading = false;
        notification.error(Errors.data.get);
      });

    // builder
    //   .addCase(fetchCopyTemplateStrategy.pending, (state) => {
    //     // state.isLoading = true;
    //   })
    //   .addCase(fetchCopyTemplateStrategy.fulfilled, (state, { payload }) => {
    //     const { data } = payload;
    //     if (state.list && data) {
    //       const newStrategy: IStrategyData = {
    //         ...payload.data,
    //         permissions: {
    //           has_read: true,
    //           has_delete: false,
    //           has_write: true,
    //         },
    //       };
    //       state.list = [newStrategy, ...state.list];
    //     }
    //     notification.success("Strategy template has successful copied.");
    //   })
    //   .addCase(fetchCopyTemplateStrategy.rejected, (state) => {
    //     state.isLoading = false;
    //     notification.error(Errors.copy.strategy);
    //   });
  },
});

const state = (state: RootState) => state;

export const selectTemplateStrategies = createSelector(state, (state) => state.templateStrategies);

export const { initList, setPage, setPerPage, setFilter, resetFilter } =
  templateStrategiesSlice.actions;

export default templateStrategiesSlice.reducer;
