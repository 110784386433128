import {
  Action,
  AnyAction,
  combineReducers,
  configureStore,
  Reducer,
  ThunkAction,
} from "@reduxjs/toolkit";

import { mainApi } from "@/services/apiQuery/main";

import adminPanel from "./adminPanel/adminPanel";
import adminDocuments from "./adminPanel/documents";
import companies from "./companies";
import companyCalendar from "./dataBrowser/companyCalendar";
import economicCalendar from "./dataBrowser/economicCalendar";
import discoverSearch from "./discover/discoverSearch";
import discoverDrsList from "./discover/drsList";
import discoverDrsMeta from "./discover/drsMeta";
import discoverQueryData from "./discover/queryData";
import discoverDrsDetails from "./discover/selectedDrs";
import foldersData from "./focus/foldersData";
import folderSelected from "./focus/folderSelected";
import foldersManage from "./focus/foldersManage";
import updateOdinCharts from "./focus/updateOdinCharts";
import forecastChart from "./forecast/forecastChart";
import forecastEquities from "./forecast/forecastEquities";
import forecastEtfIntelligence from "./forecast/forecastEtfIntelligence";
import forecastMacro from "./forecast/forecastMacro";
import forecastTable from "./forecast/forecastTable";
import home from "./home";
import homeStart from "./homeStart";
import keyDrivers from "./keyDrivers";
import login from "./login";
import market from "./market";
import menu from "./menu";
import odinChat from "./odinchat";
import pagesPermission from "./pagesPermission";
import pdfReport from "./pdfReport";
import benchmarks from "./portfolios/benchmarks";
import customStrategies from "./portfolios/customeStrategies";
import permissions from "./portfolios/permissions";
import portfolio from "./portfolios/portfolio";
import portfolioBuilder from "./portfolios/portfolioBuilder";
import portfolioHedging from "./portfolios/portfoliosHedging";
import templatePortfolio from "./portfolios/templatePortfolios";
import profile from "./profile";
import userReport from "./report";
import commonStrategies from "./strategies/commonStrategies";
import customIndicator from "./strategies/customIndicator";
import myStrategies from "./strategies/my_strategies";
import strategyPermissions from "./strategies/permissions";
import strategies from "./strategies/strategies";
import templateStrategies from "./strategies/template_strategies";
import strategiesBackTesting from "./strategiesBackTesting";
import tickers from "./tikers";
import users from "./users";
import warRoom from "./warRoom";

export const combinedReducer = combineReducers({
  home,
  menu,
  login,
  market,
  profile,
  odinChat,
  portfolio,
  templatePortfolio,

  strategyPermissions,
  strategiesBackTesting,
  portfolioBuilder,
  tickers,
  permissions,
  users,
  economicCalendar,
  companyCalendar,
  benchmarks,

  forecastMacro,
  forecastChart,
  forecastTable,
  forecastEquities,

  strategies,
  myStrategies,
  commonStrategies,
  templateStrategies,
  customStrategies,

  warRoom,
  forecastEtfIntelligence,
  foldersManage,
  folderSelected,
  updateOdinCharts,
  keyDrivers,

  discoverSearch,
  discoverDrsMeta,
  discoverDrsList,
  discoverDrsDetails,
  discoverQueryData,

  companies,
  customIndicator,
  portfolioHedging,
  userReport,
  foldersData,
  [mainApi.reducerPath]: mainApi.reducer,
  adminPanel,
  adminDocuments,
  pdfReport,
  pagesPermission,
  homeStart,
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  // Reset Store after Logout
  if (action.type === "login/init") {
    state = {} as RootState;
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(mainApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof combinedReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
