import { FC, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Avatar, GoBack, InfoHeading, LoaderWrapper, Tab } from "@/components";
import { useAppSelector, useUserPermissions } from "@/hooks";
import { profileInfo } from "@/store/profile";
import { usersState } from "@/store/users";

import { UserInfoDetails } from "../UserProfile/components/UserInfo/UserInfo";
import { UserActivity } from "../UsersManagement/components/UserActivity/UserActivity";
import { UserPermissions } from "../UsersManagement/components/UserPermissions/UserPermissions";
import { UsersEdit } from "../UsersManagement/components/UsersEdit/UsersEdit";

import styles from "./UserStatusDetails.module.scss";

type TabItemType = "modify" | "access" | "permissions";

export const UserStatusDetails: FC = () => {
  const { users, isLoading } = useAppSelector(usersState);
  const { profile } = useAppSelector(profileInfo);
  const [selectedTab, setSelectedTab] = useState<TabItemType>("modify");
  const { userId } = useParams();
  const { userRole } = useUserPermissions();
  const navigate = useNavigate();

  const selectedUser = useMemo(() => {
    return users
      ? users.filter((u) => u.id !== profile?.id).find((user) => `${user.id}` === userId) || null
      : null;
  }, [users, userId, profile]);

  const selectTabHandle = (key: string) => {
    setSelectedTab(key as TabItemType);
  };

  const tabs = useMemo(() => {
    const allTabs = [
      {
        key: "modify",
        title: "Modify Access",
        component: <UsersEdit selected={selectedUser} />,
      },
      {
        key: "activity",
        title: "User Activity",
        component: <UserActivity selected={selectedUser} />,
      },
    ];

    // check if user is SuperAdmin
    if (userRole.isSuperAdmin)
      allTabs.push({
        key: "permission",
        title: "Access Permissions",
        component: <UserPermissions selectedUser={selectedUser} />,
      });

    return allTabs;
  }, [selectedUser]);

  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <InfoHeading>Users Management</InfoHeading>
        <div className={styles.heading}>User Status Details</div>
        <div className={styles.navigation}>
          <GoBack onClick={() => navigate(-1)} text="Go Back" type="primary" />
        </div>
        <section className={styles.infoSection}>
          <Avatar
            wrapped
            url={selectedUser?.profile_image || null}
            active={!!selectedUser?.is_active}
          />
          <div className={styles.infoDetails}>
            <UserInfoDetails profile={selectedUser} product={"modeling"} showActiveStatus />
          </div>
        </section>
        <section className={styles.mainSection}>
          <LoaderWrapper loading={isLoading} transparent>
            <div className={styles.tabsContainer}>
              <Tab tabs={tabs} selected={selectedTab} onChange={selectTabHandle} />
            </div>
          </LoaderWrapper>
        </section>
      </div>
    </div>
  );
};
